import { Directive, OnInit, Input, HostListener, HostBinding } from '@angular/core';

@Directive({
  selector: '[wfCardTableSort]',
})
export class CardTableSortDirective implements OnInit {

  protected _elementClass: string[] = [];

  @Input('wfCardTableSort') sortFn: Function;
  @Input('sortkey') sortkey: string;

  @HostListener('click', ['$event']) onClick($event) {
    if (this.sortFn && this.sortkey) {
      const sortdir = this.sortFn(this.sortkey);
      this.ElementClassItemDelete('card-table-sort-up');
      this.ElementClassItemDelete('card-table-sort-down');
      if (sortdir === 'asc') {
        this._elementClass.push('card-table-sort-up');
      } else {
        this._elementClass.push('card-table-sort-down');
      }
    }
  }

  @HostBinding('class')
  get elementClass(): string {
    return this._elementClass.join(' ');
  }
  set(val: string) {
    this._elementClass = val.split(' ');
  }

  constructor() { }

  ngOnInit() {
    this._elementClass.push('cursor-pointer');
  }

  ElementClassItemDelete(class_str: string) {
    let _finded = false;
    for (let _i = 0; _i < this._elementClass.length; _i++) {
      if (this._elementClass[_i] === class_str) {
        this._elementClass.splice(_i, 1);
        _finded = true;
        break;
      }
    }
    if (_finded) {
      this.ElementClassItemDelete(class_str);
    } else {
      return;
    }
  }

}
