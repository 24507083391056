import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { NbLayoutScrollService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { WaffleLayoutsService } from './../../../@core/data/waffle-layouts.service';

type ScrollTopPosition =
  'fixed'
  | 'absolute'
  | 'relative'
  | 'sticky'
  | 'static';


@Component({
  selector: 'wf-scroll-top',
  templateUrl: './waffle-scroll-top.component.html',
  styleUrls: ['./waffle-scroll-top.component.scss'],
})

export class WaffleScrollTopComponent implements OnInit, OnChanges, OnDestroy {

  @Input() top: string = 'auto';
  @Input() bottom: string = '3rem';
  @Input() left: string = 'auto';
  @Input() right: string = '1rem';
  @Input() zIndex: number = 999;
  @Input() position: ScrollTopPosition = 'fixed';
  @Input() isVisible: boolean = false;
  @Input() selfManageScroll: boolean = true;

  subscribe_scrollTransmission: Subscription;
  subscribe_layoutScroll: Subscription;

  lastScrollTop: number = 0;

  constructor(
    private layoutScrollService: NbLayoutScrollService,
    private wafflelayoutsservice: WaffleLayoutsService,
  ) { }

  ngOnInit() {
    if (this.selfManageScroll) {
      this.subscribe_scrollTransmission =
        this.wafflelayoutsservice.getScrollTransmission()
          .subscribe(obs => {
            this.subscribe_layoutScroll = obs
              .pipe(throttleTime(150))
              .subscribe(e => {
                if (e) {
                  const { scrollTop, offsetHeight } = e.target;

                  if (this.lastScrollTop < scrollTop) {
                    (offsetHeight / 2) < scrollTop
                      ? (!this.isVisible && (this.isVisible = true))
                      : (this.isVisible && (this.isVisible = false));
                  } else {
                    (offsetHeight * 0.4) < scrollTop
                      ? (!this.isVisible && (this.isVisible = true))
                      : (this.isVisible && (this.isVisible = false));
                  }

                  this.lastScrollTop = scrollTop;
                }
              });
          });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const { isVisible } = changes;
    if (isVisible && isVisible.currentValue) {
      this.styles.right = this.right;
    } else {
      this.styles.right = '-5rem';
    }
  }

  ngOnDestroy() {
    if (this.subscribe_scrollTransmission) this.subscribe_scrollTransmission.unsubscribe();
    if (this.subscribe_layoutScroll) this.subscribe_layoutScroll.unsubscribe();
  }

  get styles() {
    return {
      'position': this.position,
      'z-index': this.zIndex,
      'top': this.top,
      'bottom': this.bottom,
      'left': this.left,
      'right': this.isVisible ? this.right : '-5rem',
    };
  }

  scrollTop() {
    this.layoutScrollService.scrollTo(0, 0);
  }

}
