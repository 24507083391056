import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange } from '@angular/core';

export interface Data {
  id: string;
  name: string;
  selected: boolean;
}

export interface ShowData {
  show: Data;
  source: any;
}
export interface ArraySizeTwo {
  0: any;
  1: any;
}

@Component({
  selector: 'wf-list-selector',
  templateUrl: './waffle-list-selector.component.html',
  styleUrls: ['./waffle-list-selector.component.scss'],
})
export class WaffleListSelectorComponent implements OnInit, OnChanges {

  // 需要被選擇的陣列
  @Input() source: Array<any>;
  // 要顯示的兩個欄位
  @Input() showdata: ArraySizeTwo;
  // 將被選的資料回傳
  @Output() output: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();
  // pagination
  listPage: number = 1;
  maxPageItems: number = 10;
  pageContent: Array<ShowData>;
  pageLists: Array<ShowData>[];
  collectionSize: number = 0;
  //
  data_keep: Array<ShowData> = [];
  //
  search_value: string = '';
  allchecked: boolean = false;

  constructor() { }

  ngOnInit() {

  }

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    const _source = changes['source'];
    if (_source) {
      this.source = _source.currentValue;
      if (this.source && this.source.length > 0 && this.showdata) {
        this.data_keep = [];
        for (const item of this.source) {
          this.data_keep = [...this.data_keep, {
            show: {
              id: (item[this.showdata[0]]) ? item[this.showdata[0]] : '',
              name: (item[this.showdata[1]]) ? item[this.showdata[1]] : '',
              selected: false,
            },
            source: item,
          }];
        }
      }
      this.collectionSize = this.data_keep.length;
      this.getPageLists();
    }
  }

  // pagination
  handlePageChange(pageNumber) {
    this.pageContent = this.pageLists[pageNumber - 1];
    this.listPage = pageNumber;
  }
  // pagination
  getPageLists() {
    const results = [];
    const temp_list_data = [...this.data_keep];

    while (temp_list_data.length) {
      results.push(temp_list_data.splice(0, this.maxPageItems));
    }

    this.pageLists = results;
    this.handlePageChange(1);
  }
  // pagination
  getSearchList(filtered) {
    const results = [];
    const temp_filtered = filtered;

    while (temp_filtered.length) {
      results.push(temp_filtered.splice(0, this.maxPageItems));
    }
    this.pageLists = results;
    this.handlePageChange(1);
  }
  // pagination
  onListSizeSelect(maxPageItems: number) {
    this.maxPageItems = maxPageItems;
    this.getPageLists();
  }

  FilterData() {
    const self = this;

    const temp = self.data_keep.filter(data => {
      return `${data.show.id}${data.show.name}`.toLowerCase().includes(self.search_value.toLowerCase());
    });

    // AllChecked
    self.allchecked = self.HasAllChecked(temp);

    this.collectionSize = temp.length;
    this.getSearchList(temp);
    this.listPage = 1;
  }

  HasAllChecked(array: Array<ShowData>) {
    for (const item of array) {
      if (!item.show.selected) return false;
    }
    return true;
  }

  AllChecked() {
    if (this.pageLists.length > 0) {
      this.allchecked = !this.allchecked;
      for (const pagelist_item of this.pageLists) {
        for (const pagecontent_item of pagelist_item) {
          pagecontent_item.show.selected = this.allchecked;
        }
      }
    }
    this.emit();
  }

  PartChecked() {
    // AllChecked
    this.allchecked = this.HasAllChecked(this.data_keep);
    this.emit();
  }

  emit() {
    const _temp = [];
    for (const item of this.data_keep) {
      if (item.show.selected) {
        _temp.push(item.source);
      }
    }
    this.output.emit(_temp);
  }

  GetSelectedCount() {
    let _count = 0;
    if (this.pageLists.length > 0) {
      for (const pagelist_item of this.pageLists) {
        for (const pagecontent_item of pagelist_item) {
          if (pagecontent_item.show.selected) _count++;
        }
      }
    }
    return _count;
  }

}
