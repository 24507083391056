<nb-card class="time-filter__card">
  <nb-card-header class="time-filter__card-header">
    <h3>{{ title | translate }}</h3>
    <span class="cursor-pointer text-color-gray"
      (click)="dismiss(true)"><i class="fal fa-times fa-2x"></i></span>
  </nb-card-header>
  <nb-card-body class="time-filter__card-body">
    <button *ngFor="let item of filter_btn"
      class="time-filter__timeline"
      nbButton
      [outline]="timeline !== item.id"
      status="primary"
      shape="round"
      (click)="onFilter(item.id)">{{ item.key | translate }}</button>
  </nb-card-body>
  <nb-card-footer class="time-filter__card-footer">
    <button nbButton
      fullWidth
      status="success"
      shape="round"
      (click)="dismiss()">
      {{ 'TimeFilter.DataCount' | translate }} ( {{ data_filtered.length }} )
    </button>
  </nb-card-footer>
</nb-card>
