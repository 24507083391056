export const environment = {
  production: false,
  ProjectVersion: `0.1.1`,
  ApiEndPoint: `https://dev.waffle.city`,
  firebase: {
    apiKey: 'AIzaSyBVI8GddbK5z5yP7kOorh6uVRy3k9xeNZU',
    authDomain: 'tw-wafflecity-prop-dev.firebaseapp.com',
    databaseURL: 'https://tw-wafflecity-prop-dev.firebaseio.com',
    projectId: 'tw-wafflecity-prop-dev',
    storageBucket: 'tw-wafflecity-prop-dev.appspot.com',
    messagingSenderId: '127644182700',
  },
  google: {
    ga_track_id: 'UA-145857588-1',
  },
};
