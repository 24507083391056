import { Component, OnInit, OnChanges, SimpleChanges, Input, TemplateRef, ContentChild } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'wf-card-table',
  templateUrl: './waffle-card-table.component.html',
  styleUrls: ['./waffle-card-table.component.scss'],
})
export class WaffleCardTableComponent implements OnInit, OnChanges {

  // List Data
  @Input() main_array: Array<any> = [];
  // List grid template column
  @Input() list_grid_template_columns = 'repeat(auto-fit, minmax(1px, 1fr))';
  // List item cursor style to pointer
  @Input() list_item_cursor_pointer: boolean = false;
  // List filter function need return string, Will input main_array item in function
  @Input() list_filter_function: Function;
  // List filter string
  @Input() list_filter_string: string;
  // No item msg
  @Input() no_item_msg: string = 'ListLengthZero';

  @ContentChild('tableheader', { static: true, read: TemplateRef }) headerLayoutTemplate: TemplateRef<any>;
  @ContentChild('tablebody', { static: true, read: TemplateRef }) bodyLayoutTemplate: TemplateRef<{ Item: any }>;
  @ContentChild('tablefooter', { static: true, read: TemplateRef }) footerLayoutTemplate: TemplateRef<{ Item: any }>;

  // @ViewChild('tableheaderVC', { read: ViewContainerRef }) tableheader_vc: ViewContainerRef;

  main_array_keep: Array<any> = [];
  sortdir: { [key: string]: string } = {};
  // pagination
  listPage: number = 1;
  maxPageItems: number = 10;
  pageContent: Array<any>;
  pageLists: Array<any>[];
  collectionSize: number = 0;

  constructor() { }

  ngOnInit() {
    // const _view = this.headerLayoutTemplate.createEmbeddedView(null);
    // this.tableheader_vc.insert(_view);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['main_array']) {
      this.main_array_keep = [...this.main_array];
      this.FilterData();
    }
    if (changes['list_filter_string']) {
      this.FilterData();
    }
  }

  // pagination
  handlePageChange(pageNumber) {
    this.pageContent = this.pageLists[pageNumber - 1];
    if (this.pageContent) {
      for (let i = 0; i < this.pageContent.length; i++) {
        this.pageContent[i]['page_index'] = (this.listPage - 1) * this.maxPageItems + (i + 1);
      }
    }
    this.listPage = pageNumber;
  }
  // pagination
  getPageLists() {
    const results = [];
    const temp_list_data = [...this.main_array];

    while (temp_list_data.length) {
      results.push(temp_list_data.splice(0, this.maxPageItems));
    }

    this.pageLists = results;
    this.handlePageChange(1);
  }
  // pagination
  getSearchList(filtered) {
    const results = [];
    const temp_filtered = [...filtered];

    while (temp_filtered.length) {
      results.push(temp_filtered.splice(0, this.maxPageItems));
    }
    this.pageLists = results;
    this.handlePageChange(1);
  }
  // pagination
  onListSizeSelect(maxPageItems: number) {
    this.maxPageItems = maxPageItems;
    this.getPageLists();
  }

  async FilterData() {
    if (this.list_filter_function && this.list_filter_string) {
      const val = this.list_filter_string.toLowerCase();

      const _temp = [];
      for (const o of this.main_array_keep) {
        if (`${await this.list_filter_function(o)}`.toLocaleLowerCase().includes(val)) {
          _temp.push(o);
        }
      }
      this.collectionSize = _temp.length;
      this.getSearchList(_temp);
    } else {
      this.collectionSize = this.main_array_keep.length;
      this.getSearchList(this.main_array_keep);
    }
  }

  Sort(columnName: string) {
    if (columnName && columnName !== '' && this.main_array_keep.length > 0) {

      // check data is exist
      const _is_exist = this.LoopColumnNameProperty(this.main_array_keep[0], columnName);
      if (_is_exist === null || _is_exist === undefined) return '';

      if (this.sortdir[columnName]) {
        this.sortdir[columnName] = (this.sortdir[columnName] === 'asc') ? 'desc' : 'asc';
      } else {
        this.sortdir[columnName] = 'asc';
      }
      const _temp = this.main_array_keep.sort((l, r) => {
        const _l = this.LoopColumnNameProperty(l, columnName);
        const _r = this.LoopColumnNameProperty(r, columnName);
        if (this.sortdir[columnName] === 'asc') {
          return this.SortFunction(_l, _r);
        } else {
          return this.SortFunction(_r, _l);
        }
      });
      this.collectionSize = _temp.length;
      this.getSearchList(_temp);
    }
    return (this.sortdir[columnName]) ? this.sortdir[columnName] : '';
  }

  SortFunction(l, r) {
    if (typeof l === 'number') return l - r;
    if (moment(l, moment.ISO_8601).isValid()) return moment(l, moment.ISO_8601).diff(moment(r, moment.ISO_8601));
    if (typeof l === 'string') return l.localeCompare(r);
    return l - r;
  }

  LoopColumnNameProperty(data: any, columnName: string) {
    const _split_column_name = columnName.split('.');
    let _output;
    for (const item of _split_column_name) {
      if (_output) {
        if (_output[item] === null || _output[item] === undefined) {
          console.error(`not found '${item}' in ${columnName}`);
          return null;
        } else {
          _output = _output[item];
        }
      } else {
        if (data[item] === null || data[item] === undefined) {
          console.error(`not found '${item}' in ${columnName}`);
          return null;
        } else {
          _output = data[item];
        }
      }
    }
    return _output;
  }

}
