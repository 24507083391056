import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';

export interface IScannerCameraData {
  deviceId: string;
  groupId: string;
  kind: string;
  label: string;
}

/*
  DOCUMENTATION: https://github.com/zxing-js/ngx-scanner/wiki/Advanced-Usage
*/

@Component({
  selector: 'wf-qrcode-scanner',
  templateUrl: './qrcode-scanner.component.html',
  styleUrls: ['./qrcode-scanner.component.scss'],
})
export class QrcodeScannerComponent implements OnInit, OnChanges {
  @Input() scannerIsEnabled: boolean = false;
  @Output() camerasFound: EventEmitter<IScannerCameraData[]> = new EventEmitter();
  @Output() camerasNotFound: EventEmitter<any> = new EventEmitter();
  @Output() scanSuccess: EventEmitter<string> = new EventEmitter();
  @Output() scanError: EventEmitter<any> = new EventEmitter();

  @ViewChild('scanner', { static: true }) scanner;

  ready: boolean = false;
  noCameras: boolean = false;
  message: string;
  hasPermission: boolean = false;

  constructor(
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['scannerIsEnabled']) {
      this.scannerIsEnabled = changes['scannerIsEnabled'].currentValue;
    }
  }

  onCamerasFound(cameras: IScannerCameraData[]) {
    this.noCameras = false;
    this.camerasFound.next(cameras);
  }

  onCamerasNotFound(e) {
    this.camerasNotFound.next(e);
    this.noCameras = true;
    this.ready = true;
    this.message = 'Package.Scanner.NoCameras';
  }

  onScanSuccess(message: string) {
    this.scanSuccess.next(message);
  }
  onScanError(e) {
    this.scanError.next(e);
  }

  onAutoStarted() {
    this.ready = true;
  }

  onPermissionResponse(hasPermission) {
    this.hasPermission = hasPermission;
    if (!this.noCameras && !hasPermission) {
      this.message = 'Package.Scanner.NoPermission';
    }
  }
}
