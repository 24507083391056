import { Component, OnInit, Input, HostBinding, HostListener, Renderer2, ElementRef } from '@angular/core';

type WfActionStatus =
  'primary'
  | 'danger'
  | 'success'
  | 'warning'
  | 'info'
  | 'neutral';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'span[wfAction], button[wfAction], label[wfAction]',
  template: `
    <ng-content></ng-content>
  `,
  styleUrls: ['./waffle-action-button.component.scss'],
})
export class WaffleActionButtonComponent implements OnInit {

  @Input() status: WfActionStatus = 'primary';


  @Input()
  @HostBinding('attr.aria-disabled')
  @HostBinding('class.wf-disabled')
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = this.convertToBoolProperty(value);
    this.renderer.setProperty(this.hostElement.nativeElement, 'disabled', this.disabled);
  }
  private _disabled: boolean = false;

  constructor(
    protected renderer: Renderer2,
    protected hostElement: ElementRef<HTMLElement>,
  ) { }

  ngOnInit() {
  }

  convertToBoolProperty(val: any): boolean {
    if (typeof val === 'string') {
      val = val.toLowerCase().trim();

      return (val === 'true' || val === '');
    }

    return !!val;
  }

  @HostBinding('class.wf-primary')
  get primary() {
    return this.status === 'primary';
  }

  @HostBinding('class.wf-info')
  get info() {
    return this.status === 'info';
  }

  @HostBinding('class.wf-success')
  get success() {
    return this.status === 'success';
  }

  @HostBinding('class.wf-warning')
  get warning() {
    return this.status === 'warning';
  }

  @HostBinding('class.wf-danger')
  get danger() {
    return this.status === 'danger';
  }

  @HostBinding('class.wf-neutral')
  get neutral() {
    return this.status === 'neutral';
  }

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    if (this.disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

}
