import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { NbTokenService } from '@nebular/auth';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, timeout } from 'rxjs/operators';

export class WaffleResponse {
  code: number;
  statusCode: number;
  data: object;
  msg: string;
}

export class WaffleParameterErrorResponse { // 紀錄用 無實質使用
  statusCode: number;
  message: string;
}

@Injectable()
export class HttpClientService {

  waffleresponse: WaffleResponse = {
    code: 0,
    statusCode: 0,
    data: [],
    msg: '',
  };

  constructor(private http: HttpClient,
    private tokenservice: NbTokenService) { }

  private setAuthorization(options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }) {
    let _authorization: string;
    this.tokenservice.get().subscribe(data => _authorization = data.toString()).unsubscribe();
    if (options.headers instanceof HttpHeaders) {
      options.headers = options.headers.set('Authorization', _authorization);
    } else {
      options.headers['Authorization'] = _authorization;
    }
    return options;
  }

  get<T>(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }, auth: boolean = true): Observable<T> {
    const _options = (auth) ? this.setAuthorization(options) : options ;
    return this.http.get<any>(url, _options).pipe(
      timeout(30000),
      retry(3),
      catchError(this.handleError),
    );
  }

  getp<T>(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }, auth: boolean = true): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const _options = (auth) ? this.setAuthorization(options) : options ;
      this.http.get<any>(url, _options).pipe(
        timeout(30000),
        retry(3),
        catchError(this.handleError),
      ).subscribe(
        data => {
          resolve(data);
          return;
        },
        error => {
          reject(error);
          return;
        },
      );
    });
  }

  post<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }, auth: boolean = true): Observable<T> {
    const _options = (auth) ? this.setAuthorization(options) : options ;
    return this.http.post<any>(url, body, _options).pipe(
      timeout(30000),
      catchError(this.handleError),
    );
  }

  postp<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }, auth: boolean = true): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const _options = (auth) ? this.setAuthorization(options) : options ;
      this.http.post<any>(url, body, _options).pipe(
        timeout(30000),
        catchError(this.handleError),
      ).subscribe(
        data => {
          resolve(data);
          return;
        },
        error => {
          reject(error);
          return;
        },
      );
    });
  }

  put<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }, auth: boolean = true): Observable<T> {
    const _options = (auth) ? this.setAuthorization(options) : options ;
    return this.http.put<any>(url, body, _options).pipe(
      timeout(30000),
      retry(1),
      catchError(this.handleError),
    );
  }

  putp<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }, auth: boolean = true): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const _options = (auth) ? this.setAuthorization(options) : options ;
      this.http.put<any>(url, body, _options).pipe(
        timeout(30000),
        retry(1),
        catchError(this.handleError),
      ).subscribe(
        data => {
          resolve(data);
          return;
        },
        error => {
          reject(error);
          return;
        },
      );
    });
  }

  delete<T>(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }, auth: boolean = true): Observable<T> {
    const _options = (auth) ? this.setAuthorization(options) : options ;
    return this.http.delete<any>(url, _options).pipe(
      timeout(30000),
      retry(1),
      catchError(this.handleError),
    );
  }

  deletep<T>(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }, auth: boolean = true): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const _options = (auth) ? this.setAuthorization(options) : options ;
      this.http.delete<any>(url, _options).pipe(
        timeout(30000),
        retry(1),
        catchError(this.handleError),
      ).subscribe(
        data => {
          resolve(data);
          return;
        },
        error => {
          reject(error);
          return;
        },
      );
    });
  }

  patch<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }, auth: boolean = true): Observable<T> {
    const _options = (auth) ? this.setAuthorization(options) : options ;
    return this.http.patch<any>(url, body, _options).pipe(
      timeout(30000),
      retry(1),
      catchError(this.handleError),
    );
  }

  patchp<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }, auth: boolean = true): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const _options = (auth) ? this.setAuthorization(options) : options ;
      this.http.patch<any>(url, body, _options).pipe(
        timeout(30000),
        retry(1),
        catchError(this.handleError),
      ).subscribe(
        data => {
          resolve(data);
          return;
        },
        error => {
          reject(error);
          return;
        },
      );
    });
  }

  head<T>(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }, auth: boolean = true): Observable<T> {
    const _options = (auth) ? this.setAuthorization(options) : options ;
    return this.http.head<any>(url, _options).pipe(
      timeout(30000),
      retry(1),
      catchError(this.handleError),
    );
  }

  headp<T>(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }, auth: boolean = true): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const _options = (auth) ? this.setAuthorization(options) : options ;
      this.http.head<any>(url, _options).pipe(
        timeout(30000),
        retry(1),
        catchError(this.handleError),
      ).subscribe(
        data => {
          resolve(data);
          return;
        },
        error => {
          reject(error);
          return;
        },
      );
    });
  }

  private handleError(error: HttpErrorResponse) {
    const _tempResponse: WaffleResponse = {
      code: -1,
      statusCode: -1,
      data: [],
      msg: '',
    };
    if (error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      _tempResponse.code = -1;
      _tempResponse.msg = error.name;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.error.code || error.error.statusCode) {
        _tempResponse.code = (error.error.code) ? error.error.code : error.error.statusCode;
        _tempResponse.statusCode = error.status;
        _tempResponse.msg = (error.error.msg) ? error.error.msg : error.error.message;
      } else {
        _tempResponse.code = (error.status === 0) ? -1 : error.status;
        _tempResponse.statusCode = error.status;
        _tempResponse.msg = (error.status === 0) ? 'Http failure response' : error.message;
      }
    }
    console.error(_tempResponse);
    // return an observable with a user-facing error message
    return throwError(_tempResponse);
  }
}
