import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

export interface LoadingData {
  _number: number;
  _total: number;
}

@Component({
  selector: 'wf-waffle-loading-dialog',
  templateUrl: './waffle-loading-dialog.component.html',
  styleUrls: ['./waffle-loading-dialog.component.scss'],
})
export class WaffleLoadingDialogComponent implements OnInit {

  loadingdata: LoadingData = {
    _number: 0,
    _total: 0,
  };

  constructor(protected ref: NbDialogRef<WaffleLoadingDialogComponent>) { }

  ngOnInit() { }

  dismiss(response: boolean = false) {
    const t = setTimeout(() => {
      this.ref.close(response);
      clearTimeout(t);
    }, 500);
  }

}
