<nb-card class="alert-dialog"
  status="{{ cardStatus }}"
  accent="{{ cardAccent }}">
  <nb-card-header>
    <h4 class="text-center">{{ title | translate }}</h4>
  </nb-card-header>
  <nb-card-body class="alert-dialog__card-body"
    *ngIf="content && content !== ''">
    <ng-container *ngIf="content_ishtml">
      <div [innerHTML]="content"></div>
    </ng-container>
    <ng-container *ngIf="!content_ishtml">
      <div class="text-center">
        {{ content | translate }}
      </div>
    </ng-container>
  </nb-card-body>
  <nb-card-footer>
    <!-- Normal Confirmation, confirmation button on the RIGHT -->
    <ng-container *ngIf="mode === 'confirm'">
      <div class="alert-dialog__buttons-container">
        <button
          class="alert-dialog__danger-confirm alert-dialog__danger-confirm--cancel"
          nbButton
          shape="round"
          fullWidth
          (click)="dismiss(false)">{{ cancelButtonText | translate }}</button>
        <button
          class="alert-dialog__danger-confirm alert-dialog__danger-confirm--submit"
          nbButton
          shape="round"
          status="success"
          fullWidth
          (click)="dismiss(true)">{{ submitButtonText | translate }}</button>
      </div>
    </ng-container>
    <!-- For Content Deletion, confirmation on the LEFT -->
    <ng-container *ngIf="mode === 'dangerConfirm'">
      <div class="alert-dialog__buttons-container">
        <button
          class="alert-dialog__danger-confirm alert-dialog__danger-confirm--submit"
          nbButton
          shape="round"
          status="danger"
          fullWidth
          (click)="dismiss(true)">
          {{ submitButtonText | translate }}
        </button>
        <button
          class="alert-dialog__danger-confirm alert-dialog__danger-confirm--cancel"
          nbButton
          fullWidth
          shape="round"
          (click)="dismiss(false)">
          {{ cancelButtonText | translate }}
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="mode === 'alert'">
      <button class="float-right alert-dialog__danger-confirm--cancel"
        nbButton
        fullWidth
        status="primary"
        shape="round"
        (click)="dismiss(false)">{{ 'Close' | translate }}</button>
    </ng-container>
  </nb-card-footer>
</nb-card>
