import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpClientService } from './httpclient.service';
import { WaffleTranslateService, extractTranslateKey } from './waffle-translate.service';
import { environment } from '../../../environments/environment';

export interface IUserAuthCode {
  code: string;
  data: string;
}

@Injectable({
  providedIn: 'root',
})
export class WaffleAuthService {
  baseEndpoint = environment.ApiEndPoint;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'my-auth-token',
    }),
    params: new HttpParams(),
  };

  constructor(
    private http: HttpClientService,
    private waffletranslateservice: WaffleTranslateService,
  ) { }

  getUserAuthCode(code) {
    return new Promise((resolve, reject) => {
      this.httpOptions.params = new HttpParams({
        fromObject: { code },
      });
      const url = `${this.baseEndpoint}/auth/v1/user/authorization/code`;

      this.http.get(url, this.httpOptions)
        .subscribe(
          (res: IUserAuthCode) => {
            resolve(res.data);
          },
          err => {
            this.waffletranslateservice.toastrDanger(`Code: ${err.code} - Message: ${err.msg}`, extractTranslateKey.Error);
            reject(err);
          },
        );

    });
  }

  wafflelogout() {
    return new Promise<boolean>((resolve, reject) => {
      this.httpOptions.params = new HttpParams();
      const url = `${this.baseEndpoint}/auth/v1/user/logout`;

      this.http.get(url, this.httpOptions)
        .subscribe(
          res => {
            resolve(true);
          },
          err => {
            reject(false);
          },
        );

    });
  }

}
