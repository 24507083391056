import { Component, OnInit, Input, ElementRef, ViewChild, SimpleChange, OnChanges } from '@angular/core';
import { WaffleTranslateService, extractTranslateKey } from '../../../@core/data/waffle-translate.service';
declare let QRCode;

@Component({
  selector: 'ngx-qrcode',
  template: `
    <div class="qrcodearea">
      <div #qrcode></div>
    </div>
  `,
  styleUrls: ['./qrcode.component.scss'],
})
export class QrcodeComponent implements OnInit, OnChanges {

  @Input() public allowEmptyString: boolean = false;
  @Input() public colordark: string = '#000000';
  @Input() public colorlight: string = '#ffffff';
  @Input() public level: string = 'M';
  @Input() public hidetitle: boolean = false;
  @Input() public qrdata: string = '';
  @Input() public size: number = 256;
  @Input() public usesvg: boolean = false;

  public qrcode: any;
  @ViewChild('qrcode', { static: true }) el: ElementRef;
  @ViewChild('qrcodelogo', { static: true }) logo_el: ElementRef;
  constructor(private waffletranslateservice: WaffleTranslateService) { }

  ngOnInit() {
    try {
      if (!this.isValidQrCodeText(this.qrdata)) {
        throw new Error('Empty QR Code data');
      }

      this.qrcode = new QRCode(this.el.nativeElement, {
        colorDark: this.colordark,
        colorLight: this.colorlight,
        correctLevel: QRCode.CorrectLevel[this.level.toString()],
        height: this.size,
        text: this.qrdata || ' ',
        useSVG: this.usesvg,
        width: this.size,
      });
    } catch (e) {
      this.waffletranslateservice.toastrDanger(e.message, extractTranslateKey.QrCodeCreater.ToastMsg.GeneratingQRcodeFail);
    }
  }

  public ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    if (!this.qrcode) {
      return;
    }

    const qrData = changes['qrdata'];

    if (qrData && this.isValidQrCodeText(qrData.currentValue)) {
      this.qrcode.clear();
      this.qrcode.makeCode(qrData.currentValue);
    }
  }

  protected isValidQrCodeText = (data: string): boolean => {
    if (this.allowEmptyString === false) {
      return !(typeof data === 'undefined' || data === '');
    }
    return !(typeof data === 'undefined');
  }

  GetImageData(qrcodedata) {
    return new Promise((resolve, reject) => {
      if (qrcodedata && this.isValidQrCodeText(qrcodedata)) {
        this.qrcode.clear();
        this.qrcode.makeCode(qrcodedata);
        const _QrcodeImg = this.el.nativeElement.querySelector('img');
        _QrcodeImg.onload = () => {
          resolve(_QrcodeImg);
        };
      } else {
        reject(`Create QRcode error`);
        this.waffletranslateservice.toastrDanger(`Data is not Valid`, extractTranslateKey.QrCodeCreater.ToastMsg.GeneratingQRcodeFail);
      }
    });
  }
}
