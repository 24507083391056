<nb-card class="card-size">
  <nb-card-header class="d-flex justify-content-between">
    <h4>{{ 'Selector' | translate }}</h4>
    <div class="text-center">
      <button nbButton
        class="mr-1 btn-close"
        shape="round"
        (click)="dismiss(false)">{{ 'Close' | translate }}</button>
      <button nbButton
        status="success"
        shape="round"
        (click)="dismiss(true)">{{ 'Apply' | translate }}</button>
    </div>
  </nb-card-header>
  <nb-card-body>
    <!-- <div class="form-group row">
      <label for="input-facilityminutesbyunit" class="col-sm-3 col-form-label">{{ 'MinutesByUnit' | translate }}</label>
      <div class="col-sm-9">
        <label class="form-check-inline">
          <input class="form-check-input" type="radio" name="TimeBlock" [value]="30" [checked]="TimeBlock === 30" (click)="TimeBlockChange($event)">
          {{ 'HalfHour' | translate }}
        </label>
      </div>
    </div> -->
    <table class="week-time-list__table table table-bordered">
      <thead>
        <tr>
          <th>
            <!-- <span (click)="TableCancelMode()" class="table-icon">
                <i class="fa-circle text-primary"
                    [class.fas]="table_selected_mode"
                    [class.fal]="!table_selected_mode">
                </i>
              </span> -->
            <span (click)="TableReset()"
              class="table-icon">
              <i class="fal fa-trash-alt text-primary text-danger"></i>
            </span>
          </th>
          <th class="text-center">{{ 'SundayMin' | translate }}</th>
          <th class="text-center">{{ 'MondayMin' | translate }}</th>
          <th class="text-center">{{ 'TuesdayMin' | translate }}</th>
          <th class="text-center">{{ 'WednesdayMin' | translate }}</th>
          <th class="text-center">{{ 'ThursdayMin' | translate }}</th>
          <th class="text-center">{{ 'FridayMin' | translate }}</th>
          <th class="text-center">{{ 'SaturdayMin' | translate }}</th>
        </tr>
      </thead>
      <tbody #table_tbody>
        <ng-container
          *ngFor="let item of week_business_hours_table;let _index = index">
          <ng-container *ngIf="TimeBlock === 30;else normalmode">
            <ng-container *ngIf="_index % 2 === 0;else odd">
              <tr>
                <td rowspan="2"
                  class="text-center">{{ item.rowname }}</td>
                <td *ngFor="let col of item.rows;let _col_index = index"
                  (mouseover)="TableMouseOver(col, $event)"
                  (mousedown)="TableMouseDown(col, $event)"
                  [class.tables-selected]="col.selected"
                  [class.tables-hover]="col.hover"
                  class="text-center"
                  [style.border-bottom.px]="0">
                </td>
              </tr>
            </ng-container>
            <ng-template #odd>
              <tr>
                <td *ngFor="let col of item.rows;let _col_index = index"
                  (mouseover)="TableMouseOver(col, $event)"
                  (mousedown)="TableMouseDown(col, $event)"
                  [class.tables-selected]="col.selected"
                  [class.tables-hover]="col.hover"
                  [style.border-top.px]="0">
                </td>
              </tr>
            </ng-template>
          </ng-container>
          <ng-template #normalmode>
            <tr>
              <td>{{ item.rowname }}</td>
              <td *ngFor="let col of item.rows;let _col_index = index"
                (mouseover)="TableMouseOver(col, $event)"
                (mousedown)="TableMouseDown(col, $event)"
                [class.tables-selected]="col.selected"
                [class.tables-hover]="col.hover"
                [style.border-bottom.px]="0">
              </td>
            </tr>
          </ng-template>
        </ng-container>
      </tbody>
    </table>
  </nb-card-body>
</nb-card>
