import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpClientService } from './httpclient.service';
import { BehaviorSubject } from 'rxjs';
import { WaffleTranslateService, extractTranslateKey } from './waffle-translate.service';
import { LocalStorageService, CacheType } from './localstorage.service';
import * as JSZip from 'jszip';
import * as FileSaver from 'file-saver';
import { QrcodeComponent } from '../../@theme/components/qrcode/qrcode.component';
import { WaffleLoadingDialogComponent } from '../../@theme/components/waffle-loading-dialog/waffle-loading-dialog.component';
import { environment } from '../../../environments/environment';

export interface InfoData {
  address: string;
  name: string;
  description: string;
  family_code: string;
  members: Array<IMembers>;
  parking: {
    cars: Array<{
      brand: string;
      color: string;
      license_plate: string;
    }>;
    spaces: Array<{
      location: string;
    }>;
  };
  proprietary: {
    area: string;
    local_phone: string;
    phone: string;
    ratio: string;
  };
}

export interface EditFamilies {
  family_id: string;
  name: string;
}

export interface PatchFamiliesCode {
  update_counts: number;
  datas: Array<{
    family_code: string;
  }>;
}

export class GetInfo {
  address: string;
  name: string;
  version: string;
}

export interface FamiliesQuery {
  community_id: string;
  data: FamiliesQueryData[];
}

export interface FamiliesQueryData {
  family_id: string;
  family_name: string;
  family_address: string;
  family_code: string;
  members: FamiliesQueryMemberData[];
}

export interface FamiliesQueryMemberData {
  user_id: string;
  name: string;
  phone: string;
}

export interface EditFamiliesProprietary {
  family_id: string;
  local_phone?: string; // 市話
  phone?: string; // 手機電話
  ratio?: string; // 比例
  area?: string; // 坪數
}

export interface EditFamiliesParking {
  family_id: string;
  cars?: Array<IParkingCars>;
  spaces?: Array<IParkingSpaces>;
}

export interface IParkingCars {
  brand?: string; // 廠牌
  color?: string; // 顏色
  license_plate?: string; // 車牌
}

export interface IParkingSpaces {
  location?: string; // 位置
}

export interface EditFamiliesMembers {
  family_id: string;
  members: Array<IMembers>;
}

export interface IMembers {
  user_id?: string; // 關聯性帳號
  user_name?: string; // 手動填寫姓名
  phone?: string; // 聯絡電話
  role?: string; // 角色 (holder、tenant、member)
}

@Injectable()
export class WaffleFamilyService {

  private FamilyLoading$ = new BehaviorSubject<boolean>(false);
  FamilyLoading = this.FamilyLoading$.asObservable();
  private FamiliesQuery$ = new BehaviorSubject<FamiliesQuery>({
    community_id: '',
    data: [],
  });
  private FamiliesQuery = this.FamiliesQuery$.asObservable();
  baseEndpoint = environment.ApiEndPoint;
  token: string;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'my-auth-token',
    }),
    params: new HttpParams(),
  };
  // query family 與 family info 合併的
  private NowFamilyInfo: { [key: string]: GetInfo } = {};
  // FamilyInfoVersion拿來判斷目前cache版本(string寫死)
  private CacheFamilyInfo: { [key: string]: GetInfo } = {};
  private FamilyInfoVersion = 'v1';

  constructor(private httpclientservice: HttpClientService,
    private waffletranslateservice: WaffleTranslateService,
    private localstorageservice: LocalStorageService) { }

  getQueryObservable(community_id: string) {
    return new Promise<Array<FamiliesQueryData>>((resolve, reject) => {
      this.FamilyLoading$.next(true);
      this.httpOptions.params = new HttpParams();
      const _base = this.baseEndpoint;
      const _communities = `/crm/v2/communities`;
      const _families = `/families`;
      this.httpclientservice.get<any>(`${_base}${_communities}/${community_id}${_families}`, this.httpOptions).subscribe(
        data => {
          this.FamilyLoading$.next(false);
          resolve(data.data);
        },
        error => {
          this.FamilyLoading$.next(false);
          this.waffletranslateservice.toastrDanger(`Code: ${error.code} - Message: ${error.msg}`, extractTranslateKey.Family.ToastMsg.QueryFamilyFail);
          reject([]);
        },
      );
    });
  }

  // David
  // 此處cache方式有兩種, 從family_query 以及 localstorage的family_info
  async getInfo(family_id: string): Promise<GetInfo> {
    if (this.NowFamilyInfo[family_id] === undefined ||
      this.NowFamilyInfo[family_id].version !== this.FamilyInfoVersion) {
      await new Promise<InfoData>((resolve, reject) => {
        this.getInfoObservable(family_id).subscribe(
          data => {
            resolve(data.data);
          },
          error => {
            this.waffletranslateservice.toastrDanger(`Code: ${error.code} - Message: ${error.msg}`, extractTranslateKey.Family.ToastMsg.QueryFamilyInfo);
            reject(error);
          },
        );
      }).then(data => {
        this.CacheFamilyInfo[family_id] = {
          name: data.name,
          address: data.address,
          version: this.FamilyInfoVersion,
        };
        this.NowFamilyInfo[family_id] = {
          name: data.name,
          address: data.address,
          version: this.FamilyInfoVersion,
        };
      });
      this.localstorageservice.setData(CacheType.FamilyInfoMap, this.CacheFamilyInfo);
    }
    return this.NowFamilyInfo[family_id];
  }

  getInfoObservable(family_id: string) {
    this.httpOptions.params = new HttpParams();
    return this.httpclientservice.get<any>(`${this.baseEndpoint}/crm/v2/families/${family_id}`, this.httpOptions);
  }

  patchFamiliesPromise(edit_data: EditFamilies): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpOptions.params = new HttpParams();
      const outputdata = {
        'name': edit_data.name,
      };
      this.httpclientservice.patch<any>(`${this.baseEndpoint}/crm/v2/families/${edit_data.family_id}`, outputdata, this.httpOptions).subscribe(
        data => {
          resolve(true);
        },
        error => {
          this.waffletranslateservice.toastrDanger(`Code: ${error.code} - Message: ${error.msg}`, extractTranslateKey.Family.ToastMsg.EditFamilyFail);
          reject(false);
        },
      );
    });
  }

  patchFamiliesCodeObservable(family_id: string) {
    this.httpOptions.params = new HttpParams();
    return this.httpclientservice.patch<any>(`${this.baseEndpoint}/crm/v2/families/${family_id}/codes`, {}, this.httpOptions);
  }

  deleteFamiliesUserObservable(family_id: string, user_id: string) {
    this.httpOptions.params = new HttpParams();
    return this.httpclientservice.delete<any>(`${this.baseEndpoint}/crm/v2/families/${family_id}/users/${user_id}`, this.httpOptions);
  }

  setQueryFamily(community_id: string, arr: Array<FamiliesQueryData>) {
    this.NowFamilyInfo = {};
    for (const item of arr) {
      if (this.NowFamilyInfo[item.family_id] === undefined ||
        this.NowFamilyInfo[item.family_id].version !== this.FamilyInfoVersion) {
        this.NowFamilyInfo[item.family_id] = {
          name: item.family_name,
          address: item.family_address,
          version: this.FamilyInfoVersion,
        };
      }
    }
    this.CacheFamilyInfo = this.localstorageservice.getData(CacheType.FamilyInfoMap);
    if (this.CacheFamilyInfo !== undefined) {
      for (const key of Object.keys(this.CacheFamilyInfo)) {
        this.NowFamilyInfo[key] = {
          name: this.CacheFamilyInfo[key].name,
          address: this.CacheFamilyInfo[key].address,
          version: this.CacheFamilyInfo[key].version,
        };
      }
    }

    // 更新FamiliesQuery
    this.FamiliesQuery$.next({
      community_id: community_id,
      data: arr,
    });
  }

  getFamiliesQuery() {
    return this.FamiliesQuery;
  }

  UpdateFamiliesQuery(community_id: string) {
    this.FamilyLoading$.next(true);
    this.httpOptions.params = new HttpParams();
    this.FamiliesQuery$.next({
      community_id: community_id,
      data: [],
    });
    const _base = this.baseEndpoint;
    const _communities = `/crm/v2/communities`;
    const _families = `/families`;
    this.httpclientservice.get<any>(`${_base}${_communities}/${community_id}${_families}`, this.httpOptions).subscribe(
      data => {
        this.FamilyLoading$.next(false);
        this.FamiliesQuery$.next({
          community_id: community_id,
          data: data.data,
        });
      },
      error => {
        this.FamilyLoading$.next(false);
        this.waffletranslateservice.toastrDanger(`Code: ${error.code} - Message: ${error.msg}`, extractTranslateKey.Family.ToastMsg.QueryFamiliesQuery);
      },
    );
  }

  patchFamiliesProprietaryPromise(edit_data: EditFamiliesProprietary) {
    return new Promise<boolean>((resolve, reject) => {
      if (!(edit_data.local_phone && edit_data.phone && edit_data.ratio && edit_data.area)) reject(false);
      this.httpOptions.params = new HttpParams();
      const outputdata = {};
      if (edit_data.local_phone) outputdata['local_phone'] = edit_data.local_phone;
      if (edit_data.phone) outputdata['phone'] = edit_data.phone;
      if (edit_data.ratio) outputdata['ratio'] = edit_data.ratio;
      if (edit_data.area) outputdata['area'] = edit_data.area;
      this.httpclientservice.patch<any>(`${this.baseEndpoint}/crm/v2/families/${edit_data.family_id}/proprietary`, outputdata, this.httpOptions).subscribe(
        data => {
          // this.waffletranslateservice.showSuccess('Family.ToastMsg.patchFamiliesProprietaryPromiseSuccess', 'Success');
          return resolve(true);
        },
        error => {
          this.waffletranslateservice.toastrDanger(extractTranslateKey.Danger, extractTranslateKey.Family.ToastMsg.patchFamiliesProprietaryPromiseFail);
          return reject(false);
        },
      );
    });
  }

  patchFamiliesParkingPromise(edit_data: EditFamiliesParking) {
    return new Promise<boolean>((resolve, reject) => {
      if (!(edit_data.cars.length > 0 && edit_data.spaces.length > 0)) return reject(false);
      this.httpOptions.params = new HttpParams();
      const outputdata = {};
      if (edit_data.cars) outputdata['cars'] = edit_data.cars;
      if (edit_data.spaces) outputdata['spaces'] = edit_data.spaces;
      this.httpclientservice.patch<any>(`${this.baseEndpoint}/crm/v2/families/${edit_data.family_id}/parking`, outputdata, this.httpOptions).subscribe(
        data => {
          // this.waffletranslateservice.showSuccess('Family.ToastMsg.patchFamiliesParkingPromiseSuccess', 'Success');
          return resolve(true);
        },
        error => {
          this.waffletranslateservice.toastrDanger(extractTranslateKey.Danger, extractTranslateKey.Family.ToastMsg.patchFamiliesParkingPromiseFail);
          return reject(false);
        },
      );
    });
  }

  patchFamiliesMembersPromise(edit_data: EditFamiliesMembers) {
    return new Promise<boolean>((resolve, reject) => {
      if (!(edit_data.members.length > 0)) return reject(false);
      this.httpOptions.params = new HttpParams();
      const outputdata = {};
      if (edit_data.members) outputdata['members'] = edit_data.members;
      this.httpclientservice.patch<any>(`${this.baseEndpoint}/crm/v2/families/${edit_data.family_id}/members`, outputdata, this.httpOptions).subscribe(
        data => {
          // this.waffletranslateservice.showSuccess('Family.ToastMsg.patchFamiliesMembersPromiseSuccess', 'Success');
          return resolve(true);
        },
        error => {
          this.waffletranslateservice.toastrDanger(extractTranslateKey.Danger, extractTranslateKey.Family.ToastMsg.patchFamiliesMembersPromiseFail);
          return reject(false);
        },
      );
    });
  }

  GetQRcodeParameter() {
    return {
      // family code
      qrcode_drawpostion: {
        x: 1800,
        y: 2500,
      },
      qrcodedata: 'default',
      canvas_familycode_fontsize: 50,
      qrcodesize: 384,
      qrcodelevel: 'H',
      canvas_familycode_width: 2504,
      canvas_familycode_height: 3532,
      familyaddress_drawpostion: {
        x: 150,
        y: 720,
      },
      familyname_drawpostion: {
        x: 150,
        y: 900,
      },
      // only code
      onlycode_canvas_qrcode_width: 550,
      onlycode_canvas_qrcode_height: 550,
      onlycode_canvas_qrcodecode_fontsize: 46,
      onlycode_qrcode_drawpostion: {
        x: 75,
        y: 25,
      },
    };
  }

  MakeOutputQRcode(_FamilyList: Array<FamiliesQueryData>, _checkpoint_bg_img: HTMLImageElement, chunk_size: number, qrcodecomponent: QrcodeComponent, zipname: string, opened_loadingdialog_instance: WaffleLoadingDialogComponent) {
    return new Promise(async (resolve, reject) => {
      const splite_datas: Array<Array<FamiliesQueryData>> = (chunk_size === 0) ? [_FamilyList] : this.splitData(_FamilyList, chunk_size);
      let pages = 1;
      let _process_count = 0;
      const canvas_familycode = document.createElement('canvas');
      const canvas_familycode_2d = canvas_familycode.getContext('2d');
      const _data = this.GetQRcodeParameter();
      canvas_familycode.width = _data.canvas_familycode_width;
      canvas_familycode.height = _data.canvas_familycode_height;
      canvas_familycode.getContext('2d').font = `${_data.canvas_familycode_fontsize}px Arial`;
      opened_loadingdialog_instance.loadingdata._total = _FamilyList.length;
      for (const datas of splite_datas) {
        const zip = new JSZip();
        let okoutput = true;
        for (const item of datas) {
          let _TextPosition;
          let _tempQrcode;
          let _canvasBlob;

          opened_loadingdialog_instance.loadingdata._number = ++_process_count;
          canvas_familycode_2d.clearRect(0, 0, _data.canvas_familycode_width, _data.canvas_familycode_height);
          canvas_familycode_2d.drawImage(_checkpoint_bg_img, 0, 0);

          await qrcodecomponent.GetImageData(item.family_code).then((data) => {
            _tempQrcode = data;
          }).catch((error) => {
            okoutput = false;
          });
          if (!okoutput) break;

          canvas_familycode_2d.drawImage(_tempQrcode, _data.qrcode_drawpostion.x, _data.qrcode_drawpostion.y);
          // Qrcode Text
          _TextPosition = this.CalculateTextPosition(item.family_code, _data.qrcode_drawpostion, _data.canvas_familycode_fontsize, canvas_familycode_2d, _data.qrcodesize);
          canvas_familycode_2d.fillText(item.family_code, _TextPosition.x, _TextPosition.y);
          // Address
          canvas_familycode_2d.fillText(item.family_address, _data.familyaddress_drawpostion.x, _data.familyaddress_drawpostion.y);
          // Name
          canvas_familycode_2d.fillText(item.family_name, _data.familyname_drawpostion.x, _data.familyname_drawpostion.y);

          _canvasBlob = this.getCanvasToBlob(canvas_familycode);
          await _canvasBlob.then(function (blob) {
            zip.file(`${item.family_address}.png`, blob);
          });
        }
        if (okoutput) {
          zip.generateAsync({ type: 'blob' })
            .then((content) => {
              const blob = new Blob([content], { type: 'application/zip' });
              FileSaver.saveAs(blob, `${zipname}-${pages}.zip`);
              if (pages === splite_datas.length) resolve();
              pages++;
            });
        } else {
          reject('create qrcode image error');
        }
      }
    });
  }

  OnlyCodeDownLoadQRcode(DoData: Array<FamiliesQueryData>, qrcodecomponent: QrcodeComponent, zipname: string, opened_loadingdialog_instance: WaffleLoadingDialogComponent) {
    return new Promise(async (resolve, reject) => {
      const _data = this.GetQRcodeParameter();
      let _canvasBlob;
      const canvas_qrcode = document.createElement('canvas');
      const canvas_qrcode_2d = canvas_qrcode.getContext('2d');
      canvas_qrcode.width = _data.onlycode_canvas_qrcode_width;
      canvas_qrcode.height = _data.onlycode_canvas_qrcode_height;
      canvas_qrcode_2d.font = `${_data.onlycode_canvas_qrcodecode_fontsize}px Arial`;
      let _process_count = 0;
      opened_loadingdialog_instance.loadingdata._total = DoData.length;
      if (DoData.length > 0) {
        const TempleteImg = new Image(_data.onlycode_canvas_qrcode_width, _data.onlycode_canvas_qrcode_height);
        const zip = new JSZip();
        let okoutput = true;
        let _TextPosition;
        let _tempQrcode;
        for (const item of DoData) {
          canvas_qrcode_2d.clearRect(0, 0, _data.onlycode_canvas_qrcode_width, _data.onlycode_canvas_qrcode_height);
          canvas_qrcode_2d.drawImage(TempleteImg, 0, 0);
          opened_loadingdialog_instance.loadingdata._number = ++_process_count;
          await qrcodecomponent.GetImageData(item.family_code).then(imagedata => {
            _tempQrcode = imagedata;
          }).catch((error) => {
            okoutput = false;
          });
          if (!okoutput) break;

          canvas_qrcode_2d.drawImage(_tempQrcode, _data.onlycode_qrcode_drawpostion.x, _data.onlycode_qrcode_drawpostion.y);
          // Name
          _TextPosition = this.CalculateTextPosition(item.family_name, _data.onlycode_qrcode_drawpostion, _data.onlycode_canvas_qrcodecode_fontsize, canvas_qrcode_2d, _data.qrcodesize);
          canvas_qrcode_2d.fillText(item.family_name, _TextPosition.x, _TextPosition.y);
          // Qrcode Text
          _TextPosition = this.CalculateTextPosition(item.family_code, _data.onlycode_qrcode_drawpostion, _data.onlycode_canvas_qrcodecode_fontsize, canvas_qrcode_2d, _data.qrcodesize);
          canvas_qrcode_2d.fillText(item.family_code, _TextPosition.x, _TextPosition.y + _data.onlycode_canvas_qrcodecode_fontsize);

          _canvasBlob = this.getCanvasToBlob(canvas_qrcode);
          await _canvasBlob.then(function (blob) {
            zip.file(`${item.family_address}.png`, blob);
          });
        }
        if (okoutput) {
          // if (DoData.length === 1) {
          //   _canvasBlob.then((blob) => {
          //     FileSaver.saveAs(blob, `${DoData[0].family_address}.png`);
          //     resolve();
          //   });
          // } else {
          //   zip.generateAsync({ type: 'blob' })
          //     .then((content) => {
          //       const blob = new Blob([content], { type: 'application/zip' });
          //       FileSaver.saveAs(blob, `${zipname}.zip`);
          //       resolve();
          //     });
          // }
          zip.generateAsync({ type: 'blob' })
            .then((content) => {
              const blob = new Blob([content], { type: 'application/zip' });
              FileSaver.saveAs(blob, `${zipname}.zip`);
              resolve();
            });
        } else {
          reject('create qrcode image error');
        }
      } else {
        reject('data length error');
      }
    });
  }

  getCanvasToBlob(canvas: HTMLCanvasElement) {
    return new Promise<Blob>((resolve, reject) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      });
    });
  }

  CalculateTextPosition(text, maindrawpostion, fontsize, canvas_familycode_2d, qrcodesize) {
    const _len: number = canvas_familycode_2d.measureText(text).width;
    let _position_x = 0, _position_y = 0;
    _position_x = maindrawpostion.x + (qrcodesize / 2) - (_len / 2);
    _position_y = maindrawpostion.y + qrcodesize + fontsize + (fontsize / 4);
    return { x: _position_x, y: _position_y };
  }

  private splitData(array: Array<any>, chunk_size: number) {
    const arrayLength = array.length;
    const tempArray: Array<any> = [];

    for (let index = 0; index < arrayLength; index += chunk_size) {
      const datas = array.slice(index, index + chunk_size);
      // Do something if you want with the group
      tempArray.push(datas);
    }

    return tempArray;
  }

}
