import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { HttpClientService } from './httpclient.service';
import { WaffleTranslateService, extractTranslateKey } from './waffle-translate.service';
import * as moment from 'moment';
import { LocalStorageService, CacheType } from './localstorage.service';
import { environment } from '../../../environments/environment';

export interface Users {
  group_id: string;
  group_type: string;
  role_type: string;
  data: Array<UsersRoleManagerData | UsersRoleUserData>;
}
// group_type = community, role_type = manager
export interface UsersRoleManagerData {
  user_id: string;
  roles: Array<string>;
}
// group_type = community, role_type = user
export interface UsersRoleUserData {
  family_id: string;
  members: Array<string>;
}

export interface DeleteUsersGroup {
  user_id: string;
  group_id: string;
  group_type: string;
  role: string;
}

export interface DeleteUsersGroupData {
  delete_counts: string;
  datas: Array<string>;
}

export interface UserInfoData {
  user_id: string;
  name: string;
  phone: string;
}

export interface UserInfoVersion {
  cache_timestamp: number;
  version: string;
}

export class UserInfo implements UserInfoVersion {
  user_id: string;
  name: string;
  phone: string;
  cache_timestamp: number;
  version: string;
}

@Injectable()
export class WaffleUserService {

  baseEndpoint = environment.ApiEndPoint;

  private UsersRoleManagerData$ = new BehaviorSubject<Users>({
    group_id: '',
    group_type: '',
    role_type: '',
    data: [],
  });
  private UsersRoleManagerData = this.UsersRoleManagerData$.asObservable();
  private UsersRoleUserData$ = new BehaviorSubject<Users>({
    group_id: '',
    group_type: '',
    role_type: '',
    data: [],
  });
  private UsersRoleUserData = this.UsersRoleUserData$.asObservable();
  private UsersRoleManagerLoading$ = new BehaviorSubject<boolean>(false);
  UsersRoleManagerLoading = this.UsersRoleManagerLoading$.asObservable();
  nowGroups: {
    group_id: string,
    group_type: string,
    enabled: boolean,
    role: string,
    settings: any,
  };
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'my-auth-token',
    }),
    params: new HttpParams(),
  };
  // UserInfoVersion拿來判斷目前cache版本(string寫死)
  private NowUserInfo: { [key: string]: UserInfo };
  private UserInfoVersion = 'v3';

  constructor(private httpclientservice: HttpClientService,
    private waffletranslateservice: WaffleTranslateService,
    private localstorageservice: LocalStorageService) {
    this.NowUserInfo = this.localstorageservice.getData(CacheType.UserInfoMap);
    if (this.NowUserInfo === undefined || this.NowUserInfo === null) {
      this.NowUserInfo = {};
    }
  }

  getProFileObservable() {
    return this.httpclientservice.get<any>(`${this.baseEndpoint}/crm/v2/users/profile`, this.httpOptions);
  }

  getUsersRoleManager() {
    return this.UsersRoleManagerData;
  }

  UpdateUsersRoleManager(community_id: string) {
    this.UsersRoleManagerLoading$.next(true);
    this.UsersRoleManagerData$.next({
      group_id: '',
      group_type: '',
      role_type: '',
      data: [],
    });
    this.httpOptions.params = new HttpParams();
    const getPath = `${this.baseEndpoint}/crm/v2/communities/${community_id}/managers`;
    this.httpclientservice.get<any>(getPath, this.httpOptions).subscribe(
      responsedata => {
        this.UsersRoleManagerLoading$.next(false);
        this.UsersRoleManagerData$.next({
          group_id: community_id,
          group_type: 'community',
          role_type: 'manager',
          data: responsedata.data,
        });
      },
      error => {
        this.UsersRoleManagerLoading$.next(false);
        this.waffletranslateservice.toastrDanger(`Code: ${error.code} - Message: ${error.msg}`, extractTranslateKey.User.ToastMsg.QueryUsersRoleManagerFail);
      },
    );
  }

  getUsersRoleUser() {
    return this.UsersRoleUserData;
  }

  getInfoObservable(user_id: string) {
    this.httpOptions.params = new HttpParams();
    this.httpOptions.params = this.httpOptions.params.set('type', 'info');
    return this.httpclientservice.get<any>(`${this.baseEndpoint}/crm/v2/users/${user_id}`, this.httpOptions);
  }

  DeleteUsersGroup(deleteusersgroup: DeleteUsersGroup) {
    this.UsersRoleManagerLoading$.next(true);
    this.httpOptions.params = new HttpParams();
    this.httpclientservice.put<any>(`${this.baseEndpoint}/crm/v1/user/group/remove`, deleteusersgroup, this.httpOptions).subscribe(
      (data) => {
        this.UsersRoleManagerLoading$.next(false);
        const _data = data.data as DeleteUsersGroupData;
        this.waffletranslateservice.toastrSuccess({ key: extractTranslateKey.User.ToastMsg.QueryDeleteUsersGroupSuccess, interpolateParams: { count: _data.delete_counts } }, extractTranslateKey.Success);
        this.UpdateUsersRoleManager(deleteusersgroup.group_id);
      },
      error => {
        this.UsersRoleManagerLoading$.next(false);
        this.waffletranslateservice.toastrDanger(`Code: ${error.code} - Message: ${error.msg}`, extractTranslateKey.User.ToastMsg.QueryDeleteUsersGroupFail);
      },
    );
  }

  async getUserInfo(user_id: string): Promise<UserInfo> {
    if (this.NowUserInfo[user_id] === undefined ||
      this.NowUserInfo[user_id].version !== this.UserInfoVersion ||
      this.NowUserInfo[user_id].cache_timestamp < Number(moment().format('X'))) {
      await this.UpdateUserInfo(user_id).then((data) => {
        this.NowUserInfo[user_id] = {
          user_id: data.user_id,
          name: data.name,
          phone: data.phone,
          cache_timestamp: Number(moment().add(1, 'hours').format('X')),
          version: this.UserInfoVersion,
        };
      });
    }
    this.localstorageservice.setData(CacheType.UserInfoMap, this.NowUserInfo);
    return this.NowUserInfo[user_id];
  }

  UpdateUserInfo(user_id: string) {
    return new Promise<UserInfoData>((resolve, reject) => {
      this.getInfoObservable(user_id).subscribe(
        data => {
          resolve(data.data);
        },
        error => {
          this.waffletranslateservice.toastrDanger(`Code: ${error.code} - Message: ${error.msg}`, extractTranslateKey.User.ToastMsg.QueryUserInfo);
          reject(error);
        },
      );
    });
  }

  UpdateUserInfoRawData(userinfodata: Array<UserInfoData>) {
    return new Promise<boolean>((resolve, reject) => {
      for (const item of userinfodata) {
        this.NowUserInfo[item.user_id] = {
          user_id: item.user_id,
          name: item.name,
          phone: item.phone,
          cache_timestamp: Number(moment().add(1, 'hours').format('X')),
          version: this.UserInfoVersion,
        };
      }
      this.localstorageservice.setData(CacheType.UserInfoMap, this.NowUserInfo);
      resolve(true);
    });
  }

  ClearUserInfo() {
    this.NowUserInfo = {};
  }

  PatchUsersPasswordPromise(phone: string, password: string) {
    return new Promise<boolean>((resolve, reject) => {
      if (phone && password && phone !== '' && password !== '') {
        this.httpOptions.params = new HttpParams();
        this.httpclientservice.patch<any>(`${this.baseEndpoint}/crm/v2/users/${phone}/password`, { 'password': password }, this.httpOptions)
          .subscribe(() => {
            this.waffletranslateservice.toastrSuccess(extractTranslateKey.User.ToastMsg.PatchUsersPasswordPromiseSuccess, extractTranslateKey.Success);
            resolve(true);
          },
            error => {
              this.waffletranslateservice.toastrDanger(`Code: ${error.code} - Message: ${error.msg}`, extractTranslateKey.User.ToastMsg.PatchUsersPasswordPromiseFail);
              reject(false);
            });
      } else {
        reject(false);
      }
    });
  }

  HeadTestUsersPhonePromise(phone: string) {
    return new Promise<boolean>((resolve, reject) => {
      this.httpOptions.params = new HttpParams();
      this.httpclientservice.head<any>(`${this.baseEndpoint}/crm/v2/users/phone/${phone}`, this.httpOptions)
        .subscribe(() => {
          resolve(true);
        },
          error => {
            reject(false);
          });
    });
  }
}
