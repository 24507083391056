import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class WaffleToastrService {

  preventDuplicatesDanger: string = '';

  constructor(private toastrService: NbToastrService,
    private translateservice: TranslateService) { }

  showPrimary(message: string, title: string) {
    this.toastrService.primary(
      this.translateservice.instant(message),
      this.translateservice.instant(title),
      { duration: 3000, icon: 'fal fa-check fa-xs' });
  }

  showSuccess(message: string, title: string) {
    this.toastrService.success(
      this.translateservice.instant(message),
      this.translateservice.instant(title),
      { duration: 3000, icon: 'fal fa-check fa-xs' });
  }

  showInfo(message: string, title: string) {
    this.toastrService.info(
      this.translateservice.instant(message),
      this.translateservice.instant(title),
      { duration: 3000, icon: 'fal fa-check fa-xs' });
  }

  showWarning(message: string, title: string) {
    this.toastrService.warning(
      this.translateservice.instant(message),
      this.translateservice.instant(title),
      { duration: 10000, icon: 'fal fa-times fa-xs' });
  }

  showDanger(message: string, title: string) {
    if (title !== this.preventDuplicatesDanger) {
      if (environment.production) {
        this.toastrService.danger(
          '',
          this.translateservice.instant(title),
          { duration: 0, icon: 'fal fa-times fa-xs' });
      } else {
        this.toastrService.danger(
          this.translateservice.instant(message),
          this.translateservice.instant(title),
          { duration: 0, icon: 'fal fa-times fa-xs' });
      }
    }
    this.preventDuplicatesDanger = title;
    setTimeout(() => {
      this.preventDuplicatesDanger = '';
    }, 3000);
  }
}
