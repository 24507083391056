import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpClientService } from './httpclient.service';
import { WaffleTranslateService, extractTranslateKey } from './waffle-translate.service';
import { LocalStorageService, CacheType } from './localstorage.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

export interface Info {
  community_id: string;
  data: InfoData;
}

export interface InfoData {
  community_code: string;
  name: string;
  address: string;
  phone: string;
  type: string;
  tags: Array<string>;
  description: string;
  activity: boolean;
  create_at: string;
  update_at: string;
  families: Array<string>;
  users: Array<string>;
  functions: Array<string>;
  address_canned: Array<{
    name: string;
  }>;
  address_main_canned: string[];
  address_sub_canned: string[];
}

export interface PostManager {
  community_id: string;
  role: string;
  expired_hour: number;
}

export interface EditCommunity {
  community_id: string;
  functions?: Array<Function>;
  tags?: Array<string>;
  name?: string;
  phone?: string;
  address_main_canned?: Array<string>;
  address_sub_canned?: Array<string>;
}

export interface Function {
  function_id: string;
}

export interface QueryAdminQueryCommunity {
  code: number;
  data: Array<AdminQueryCommunity>;
  msg: string;
}

export interface AdminQueryCommunity {
  community_id: string;
  name: string;
  address: string;
  tags: Array<string>;
}

// 管委會
export interface QueryCommunityCommittee {
  community_id: string;
  committee_id: string;
  update_at: string;
  organization: CommunityCommittee;
}
export interface CommunityCommittee {
  title: string;
  members: Array<CommunityCommitteeMembers>;
  begin_at: string;
  end_at: string;
}
export interface CommunityCommitteeMembers {
  family_id: string;
  user_id: string;
  name: string;
  phone: string;
  role: string;
}
export class AddCommunityCommittee implements CommunityCommittee {
  community_id: string;
  title: string;
  members: Array<CommunityCommitteeMembers>;
  begin_at: string;
  end_at: string;
}
export class EditCommunityCommittee implements CommunityCommittee {
  community_id: string;
  title: string;
  members: Array<CommunityCommitteeMembers>;
  begin_at: string;
  end_at: string;
}

export interface ICloudImage {
  version: string;
  saas: string;
  cloud_path: string;
  id: string;
}

export interface IContstructionsItem {
  name: String;
  title: String;
}

export interface ICommunityBuildingInfo {
  companies: {
    investment_construction: String; // 投資興建
    building_construction: String; // 建築營造
    architectural_design: String; // 建築設計
    architectural_facade: String; // 建築外觀
    steel_industry: String; // 鋼鐵工程
    landscape_design: String; // 景觀設計
    public_design: String; // 公設設計
    interior_design: String; // 空間設計
    agency: String; // 建築代銷
    marketing: String; // 整合行銷
  };
  constructions: IContstructionsItem[]; // 建築團隊
  construction_license: String; // 建造執照
  usage_license: String; // 使用執照
  min_floor: Number; // 最低樓層 B2
  max_floor: Number; // 最高樓層 23F
  house_area: String; // 坪數規劃 (26坪~46坪)
  site_area: String; // 基地面積 (3415.02坪)
  buildings: String; // 棟戶規劃 (A、B、C棟)
  rooms: String; // 房數規劃 (2~3房)
  structural: Array<string>; // 結構工程 (RC)
  images: Array<string | ICloudImage>; // 社區照片 (1~10張)
  warranty: String; // 建商保固內容 (1000字以內)
  parking: String; // 停車位數量 (汽車平面68位、汽車機械128位、機車100位)
  public_ratio: String; // 公設比
}

@Injectable()
export class WaffleCommunityService {

  baseEndpoint = environment.ApiEndPoint;
  token: string;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'my-auth-token',
    }),
    params: new HttpParams(),
  };
  private CacheCommunityInfo;

  private QueryCommunityCommittee$ = new BehaviorSubject<QueryCommunityCommittee>({
    community_id: '',
    committee_id: '',
    update_at: '',
    organization: null,
  });
  private QueryCommunityCommittee = this.QueryCommunityCommittee$.asObservable();

  constructor(private httpclientservice: HttpClientService,
    private waffletranslateservice: WaffleTranslateService,
    private localstorageservice: LocalStorageService) {
  }

  getInfoObservable(community_id: string) {
    this.httpOptions.params = new HttpParams();
    return this.httpclientservice.get<any>(`${this.baseEndpoint}/crm/v2/communities/${community_id}`, this.httpOptions);
  }

  getAdminQueryCommunity() {
    this.httpOptions.params = new HttpParams();
    return this.httpclientservice.get<QueryAdminQueryCommunity>(`${this.baseEndpoint}/crm/v2/communities`, this.httpOptions);
  }

  postManagerObservable(manager: PostManager) {
    const community_id: string = manager.community_id;
    this.httpOptions.params = new HttpParams();
    return this.httpclientservice.post<any>(`${this.baseEndpoint}/crm/v2/communities/${community_id}/managers`, manager, this.httpOptions);
  }

  patchCommunityObservable(editcommunity: EditCommunity) {
    this.httpOptions.params = new HttpParams();
    const _outputdata = {};
    // function
    if (editcommunity.functions) {
      _outputdata['functions'] = editcommunity.functions;
    }
    // tags
    if (editcommunity.tags) {
      _outputdata['tags'] = editcommunity.tags;
    }
    // name
    if (editcommunity.name && editcommunity.name !== '') {
      _outputdata['name'] = editcommunity.name;
    }
    // phone
    _outputdata['phone'] = editcommunity.phone;
    // address main canned
    if (editcommunity.address_main_canned) {
      _outputdata['address_main_canned'] = editcommunity.address_main_canned;
    }
    // address sub canned
    if (editcommunity.address_sub_canned) {
      _outputdata['address_sub_canned'] = editcommunity.address_sub_canned;
    }
    return this.httpclientservice.patch<any>(`${this.baseEndpoint}/crm/v2/communities/${editcommunity.community_id}`, _outputdata, this.httpOptions);
  }

  async getCacheCommunityInfo(community_id: string): Promise<InfoData> {
    this.CacheCommunityInfo = this.localstorageservice.getData(CacheType.CommunityInfoMap);
    if (this.CacheCommunityInfo === undefined || this.CacheCommunityInfo === null) {
      this.CacheCommunityInfo = {};
    }
    if (this.CacheCommunityInfo[community_id] === undefined) {
      await this.UpdateCacheCommunityInfo(community_id)
        .then((data) => {
          this.CacheCommunityInfo[community_id] = {
            community_id: community_id,
            community_code: data.community_code,
            address: data.address,
            name: data.name,
          };
        });
      this.localstorageservice.setData(CacheType.CommunityInfoMap, this.CacheCommunityInfo);
    }
    return this.CacheCommunityInfo[community_id];
  }

  UpdateCacheCommunityInfo(community_id: string): Promise<InfoData> {
    return new Promise((resolve, reject) => {
      this.getInfoObservable(community_id).subscribe(
        data => {
          resolve(data.data);
        },
        error => {
          this.waffletranslateservice.toastrDanger(`Code: ${error.code} - Message: ${error.msg}`, extractTranslateKey.Community.ToastMsg.QueryCommunityInfoFail);
          reject(error);
        },
      );
    });
  }

  getQueryCommunityCommittee() {
    return this.QueryCommunityCommittee;
  }

  UpdateQueryCommunityCommittee(community_id: string) {
    this.getCommitteeObservable(community_id).subscribe(
      data => {
        this.QueryCommunityCommittee$.next(data.data);
      },
      error => {
        if (error.code && error.code === 5401) {
          this.AddCommitteeObservable(community_id).subscribe(
            add_data => {
              this.getCommitteeObservable(community_id).subscribe(
                added_data => {
                  this.QueryCommunityCommittee$.next(added_data.data);
                },
                added_error => {
                  this.waffletranslateservice.toastrDanger(`Code: ${added_error.code} - Message: ${added_error.msg}`, extractTranslateKey.Community.ToastMsg.QueryCommunityCommitteeFail);
                },
              );
            },
            add_error => {
              this.waffletranslateservice.toastrDanger(`Code: ${add_error.code} - Message: ${add_error.msg}`, extractTranslateKey.Community.ToastMsg.AddCommitteeFail);
            },
          );
        } else {
          this.waffletranslateservice.toastrDanger(`Code: ${error.code} - Message: ${error.msg}`, extractTranslateKey.Community.ToastMsg.QueryCommunityCommitteeFail);
        }
      },
    );
  }

  getCommitteeObservable(community_id: string) {
    this.httpOptions.params = new HttpParams();
    return this.httpclientservice.get<any>(`${this.baseEndpoint}/crm/v2/communities/${community_id}/committees`, this.httpOptions);
  }

  AddCommitteeObservable(community_id: string) {
    this.httpOptions.params = new HttpParams();
    return this.httpclientservice.post<any>(`${this.baseEndpoint}/crm/v2/communities/${community_id}/committees`, {}, this.httpOptions);
  }

  AddCommitteeOrganizations(addcommunitycommittee: AddCommunityCommittee) {
    const _output = {
      title: addcommunitycommittee.title,
      members: addcommunitycommittee.members,
      begin_at: addcommunitycommittee.begin_at,
      end_at: addcommunitycommittee.end_at,
    };
    this.httpOptions.params = new HttpParams();
    this.httpclientservice.post<any>(`${this.baseEndpoint}/crm/v2/communities/${addcommunitycommittee.community_id}/committees/organizations`, _output, this.httpOptions).subscribe(
      data => {
        this.waffletranslateservice.toastrPrimary(extractTranslateKey.Community.ToastMsg.AddCommitteeOrganizationsSuccess, extractTranslateKey.Success);
        this.UpdateQueryCommunityCommittee(addcommunitycommittee.community_id);
      },
      error => {
        this.waffletranslateservice.toastrDanger(`Code: ${error.code} - Message: ${error.msg}`, extractTranslateKey.Community.ToastMsg.AddCommitteeOrganizationsFail);
      },
    );
  }

  EditCommitteeOrganizations(editcommunitycommittee: EditCommunityCommittee) {
    const _output = {
      title: editcommunitycommittee.title,
      members: editcommunitycommittee.members,
      begin_at: editcommunitycommittee.begin_at,
      end_at: editcommunitycommittee.end_at,
    };
    this.httpOptions.params = new HttpParams();
    this.httpclientservice.put<any>(`${this.baseEndpoint}/crm/v2/communities/${editcommunitycommittee.community_id}/committees/organizations`, _output, this.httpOptions).subscribe(
      data => {
        this.waffletranslateservice.toastrPrimary(extractTranslateKey.Community.ToastMsg.EditCommitteeOrganizationsSuccess, extractTranslateKey.Success);
        this.UpdateQueryCommunityCommittee(editcommunitycommittee.community_id);
      },
      error => {
        this.waffletranslateservice.toastrDanger(`Code: ${error.code} - Message: ${error.msg}`, extractTranslateKey.Community.ToastMsg.EditCommitteeOrganizationsFail);
      },
    );
  }

  DeleteCommitteeOrganizations(community_id: string) {
    this.httpOptions.params = new HttpParams();
    this.httpclientservice.delete<any>(`${this.baseEndpoint}/crm/v2/communities/${community_id}/committees/organizations`, this.httpOptions).subscribe(
      data => {
        this.waffletranslateservice.toastrPrimary(extractTranslateKey.Community.ToastMsg.DeleteCommitteeOrganizationsSuccess, extractTranslateKey.Success);
        this.UpdateQueryCommunityCommittee(community_id);
      },
      error => {
        this.waffletranslateservice.toastrDanger(`Code: ${error.code} - Message: ${error.msg}`, extractTranslateKey.Community.ToastMsg.DeleteCommitteeOrganizationsFail);
      },
    );
  }

  EditCommunityBuilding$(community_id: string, buildingToEdit: ICommunityBuildingInfo) {
    this.httpOptions.params = new HttpParams();
    const path = `${environment.ApiEndPoint}/crm/v2/communities/${community_id}/building`;

    return this.httpclientservice.put<any>(path, buildingToEdit, this.httpOptions);
  }
}
