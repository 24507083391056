import { Component, OnInit } from '@angular/core';
import { WaffleLayoutsService } from '../../../@core/data/waffle-layouts.service';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode
      [nbSpinner]="layout_loading"
      nbSpinnerStatus="primary"
      nbSpinnerSize="xlarge"
      [nbSpinnerMessage]="'Loading' | translate">

      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent implements OnInit {

  layout_loading: boolean = false;

  constructor(private wafflelayoutsservice: WaffleLayoutsService) { }

  ngOnInit() {
    this.wafflelayoutsservice.getLayoutLoading().subscribe(e => this.layout_loading = e);
  }
}
