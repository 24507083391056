import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class WaffleLayoutsService {

  private LayoutLoading$ = new BehaviorSubject<boolean>(false);
  private LayoutLoading = this.LayoutLoading$.asObservable();

  private scrollTransmissionSubject$ = new BehaviorSubject<Observable<any>>(new Observable);
  private scrollTransmissionObservable = this.scrollTransmissionSubject$.asObservable();

  constructor() { }

  getLayoutLoading() {
    return this.LayoutLoading;
  }

  updateLayoutLoading(data: boolean) {
    this.LayoutLoading$.next(data);
  }

  getScrollTransmission() {
    return this.scrollTransmissionObservable;
  }

  updateScrollTransmission(data: Observable<any>) {
    this.scrollTransmissionSubject$.next(data);
  }
}
