export const AccessControl = {
  patrolman: {
    create: [],
    delete: [],
    edit: [],
    query: [],
    info: [],
    output: [],
    menu: [],
    submenu: [],
  },
  member: {
    create: [],
    delete: [],
    edit: [],
    query: [],
    info: [],
    output: [],
    menu: [],
    submenu: [],
  },
  committee_member: {
    create: [],
    delete: [],
    edit: [],
    query: [],
    info: [],
    output: [],
    menu: [],
    submenu: [],
  },
  janitor: {
    create: [],
    delete: [],
    edit: [],
    query: [],
    info: [],
    output: [],
    menu: [],
    submenu: [],
  },
  committee: {
    create: [],
    delete: [],
    edit: [],
    query: [],
    info: [],
    output: [],
    menu: [],
    submenu: [],
  },
  employee: {
    create: [],
    delete: [],
    edit: [],
    query: [],
    info: [],
    output: [],
    menu: [],
    submenu: [],
  },
  supervisor: {
    create: [],
    delete: [],
    edit: [],
    query: [],
    info: [],
    output: [],
    menu: ['dashboard'],
    submenu: [],
  },
};

export const Path = {
  '/pages/overview': {
    permission: 'menu',
    resource: 'dashboard',
  },
};
