import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilitiesService } from './utilities.service';
import { WaffleUserService } from './waffle-user.service';
import { WaffleCommunityService } from './waffle-community.service';
import { WaffleFamilyService } from './waffle-family.service';
import { HttpClientService } from './httpclient.service';
import { WaffleCurrentUserService } from './waffle-currentuser.service';
import { LocalStorageService } from './localstorage.service';
import { WaffleAuthService } from './waffle-auth.service';
import { WaffleToastrService } from './waffle-toastr.service';
import { WaffleTranslateService } from './waffle-translate.service';
import { WaffleLayoutsService } from './waffle-layouts.service';
import { WaffleFunctionsService } from './waffle-functions.service';
import { WaffleAttendanceService } from './waffle-attendance.service';

const SERVICES = [
  WaffleUserService,
  WaffleCommunityService,
  WaffleFamilyService,
  HttpClientService,
  WaffleCurrentUserService,
  LocalStorageService,
  UtilitiesService,
  WaffleAuthService,
  WaffleToastrService,
  WaffleTranslateService,
  WaffleLayoutsService,
  WaffleFunctionsService,
  WaffleAttendanceService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    ...SERVICES,
  ],
})
export class DataModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: DataModule,
      providers: [
        ...SERVICES,
      ],
    };
  }
}
