import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WaffleCommunityService, InfoData, AdminQueryCommunity, ICommunityBuildingInfo } from './waffle-community.service';
import { WaffleFamilyService, FamiliesQueryData, FamiliesQueryMemberData } from './waffle-family.service';
import { WaffleUserService } from './waffle-user.service';
import { WaffleTranslateService, extractTranslateKey } from './waffle-translate.service';
import { LocalStorageService, CacheType } from './localstorage.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

export interface NowUserProfile {
  user_id: string;
  username: string;
  name: string;
  role: string;
  groups?: Array<UserProfileGroups>;
  admincommunitylist?: Array<AdminQueryCommunity>;
}

export interface UserProfileGroups {
  group_id: string;
  group_type: string;
  enabled: boolean;
  role: string;
  settings: any;
  community_id: string;
  community_name: string;
  tags: Array<string>;
}

export class NowCommunity implements InfoData {
  community_id: string;
  community_code: string;
  name: string;
  address: string;
  phone: string;
  type: string;
  tags: Array<string>;
  description: string;
  activity: boolean;
  create_at: string;
  update_at: string;
  families: Array<string>;
  users: Array<string>;
  functions: Array<string>;
  address_canned: Array<{
    name: string;
  }>;
  address_main_canned: string[];
  address_sub_canned: string[];
  role: string; // this role is in the community
  building: ICommunityBuildingInfo;
  icon: string;
}

export const BUILDING_DEFAULT = {
  companies: {
    investment_construction: '', // 投資興建
    building_construction: '', // 建築營造
    architectural_design: '', // 建築設計
    architectural_facade: '', // 建築外觀
    steel_industry: '', // 鋼鐵工程
    landscape_design: '', // 景觀設計
    public_design: '', // 公設設計
    interior_design: '', // 空間設計
    agency: '', // 建築代銷
    marketing: '', // 整合行銷
  },
  constructions: [], // 建築團隊
  construction_license: '', // 建造執照
  usage_license: '', // 使用執照
  min_floor: -2, // 最低樓層 B2 - min -10
  max_floor: 20, // 最高樓層 23F - max 101
  house_area: '', // 坪數規劃 (26坪~46坪)
  site_area: '', // 基地面積 (3415.02坪)
  buildings: '', // 棟戶規劃 (A、B、C棟)
  rooms: '', // 房數規劃 (2~3房)
  structural: [], // 結構工程 (RC)
  images: [], // 社區照片 (1~10張)
  warranty: '', // 建商保固內容 (1000字以內)
  parking: '', // 停車位數量 (汽車平面68位、汽車機械128位、機車100位)
  public_ratio: '', // 公設比
};

@Injectable()
export class WaffleCurrentUserService {

  private NowUserProfile$ = new BehaviorSubject<NowUserProfile>({
    user_id: '',
    username: '',
    name: '',
    role: '',
    groups: [],
  });
  private NowUserProfile = this.NowUserProfile$.asObservable();
  private NowCommunity$ = new BehaviorSubject<NowCommunity>({
    community_id: '',
    community_code: '',
    name: '',
    address: '',
    phone: '',
    type: '',
    tags: [],
    description: '',
    activity: false,
    create_at: '',
    update_at: '',
    families: [],
    users: [],
    functions: [],
    address_canned: [],
    address_main_canned: [],
    address_sub_canned: [],
    role: '',
    building: BUILDING_DEFAULT,
    icon: '',
  });
  private NowCommunityLoading$ = new BehaviorSubject<boolean>(false);
  NowCommunityLoading = this.NowCommunityLoading$.asObservable();
  private NowCommunity = this.NowCommunity$.asObservable();
  private NowFontSize$ = new BehaviorSubject<number>(14);
  private NowFontSize = this.NowFontSize$.asObservable();

  nowGroups: UserProfileGroups;
  subscription_getnowprofile: Subscription;
  subscription_getnowcommunity: Subscription;

  constructor(private wafflecommunityservice: WaffleCommunityService,
    private wafflefamilyservice: WaffleFamilyService,
    private waffleuserservice: WaffleUserService,
    private waffletranslateservice: WaffleTranslateService,
    private localstorage: LocalStorageService,
    private router: Router) { }

  getNowCommunity() {
    return this.NowCommunity;
  }

  UpdateNowCommunity(userprofilegroups: UserProfileGroups) {
    this.NowCommunityLoading$.next(true);
    if (userprofilegroups.group_id && userprofilegroups.group_id !== '') {
      this.wafflecommunityservice.getInfoObservable(userprofilegroups.group_id).subscribe(async data => {
        const community_info = data.data;
        // 判斷是否切換社區
        if (this.NowCommunity$.getValue().community_id !== userprofilegroups.group_id) {
          // 更新家庭
          await this.wafflefamilyservice.getQueryObservable(userprofilegroups.group_id)
            .then((family_data: FamiliesQueryData[]) => {
              this.wafflefamilyservice.setQueryFamily(userprofilegroups.group_id, family_data);
              const _temp: FamiliesQueryMemberData[] = [];
              for (const family_data_item of family_data) {
                if (family_data_item.members.length > 0) {
                  _temp.unshift(...family_data_item.members);
                }
              }
              this.waffleuserservice.UpdateUserInfoRawData(_temp);
            })
            .catch(family_error => { this.wafflefamilyservice.setQueryFamily('', family_error); });
          // 清除cache
          this.localstorage.setData(CacheType.FamilyMemberSelectorSearchRecordMap, {});
        }
        // 通報社區更新
        this.NowCommunity$.next({
          community_id: userprofilegroups.group_id,
          community_code: community_info.community_code,
          name: community_info.name,
          address: community_info.address,
          phone: community_info.phone,
          type: community_info.type,
          tags: community_info.tags,
          description: community_info.description,
          activity: community_info.activity,
          create_at: community_info.create_at,
          update_at: community_info.update_at,
          families: community_info.families,
          users: community_info.users,
          functions: community_info.functions,
          address_canned: community_info.address_canned,
          address_main_canned: community_info.address_main_canned,
          address_sub_canned: community_info.address_sub_canned,
          role: userprofilegroups.role,
          building: community_info.building,
          icon: (community_info.icon && community_info.icon.cloud_path) ? community_info.icon.cloud_path : '',
        });
        this.NowCommunityLoading$.next(false);
      });
    } else {
      this.NowCommunity$.next({
        community_id: '',
        community_code: '',
        name: '',
        address: '',
        phone: '',
        type: '',
        tags: [],
        description: '',
        activity: false,
        create_at: '',
        update_at: '',
        families: [],
        users: [],
        functions: [],
        address_canned: [],
        address_main_canned: [],
        address_sub_canned: [],
        role: '',
        building: BUILDING_DEFAULT,
        icon: '',
      });
      this.NowCommunityLoading$.next(false);
    }
  }

  getNowProfile() {
    return this.NowUserProfile;
  }

  UpdateNowProfile(data: NowUserProfile) {
    this.NowUserProfile$.next(data);
  }

  getNowFontSize() {
    return this.NowFontSize;
  }

  UpdateNowFontSize(font_size: number) {
    this.NowFontSize$.next(font_size);
  }

  // 檢查是否為第一次進入網頁
  Initloading() {
    return new Promise<string>((resolve, reject) => {
      resolve('role');
    });

  }
}
