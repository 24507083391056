import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef,
  TemplateRef,
} from '@angular/core';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { NbDialogService } from '@nebular/theme';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { WaffleTranslateService, extractTranslateKey } from '../../../@core/data/waffle-translate.service';

@Component({
  selector: 'wf-image-pdf-uploader',
  templateUrl: './image-pdf-uploader.component.html',
  styleUrls: ['./image-pdf-uploader.component.scss'],
})
export class ImagePdfUploaderComponent implements OnInit {
  @Input() isDirectionVertical: boolean = true;
  @Input() ratioWidth: number = 210;
  @Input() ratioHeight: number = 297;
  @Input() UploadMax: number = 15;
  @Input() width: string = this.isDirectionVertical ? '315px' : '445.5px';
  @Input() height: string = this.isDirectionVertical ? '445.5px' : '315px';
  @Input() displayImageWidth: string = '20%';
  aspectRatio: number = this.isDirectionVertical
    ? this.ratioWidth / this.ratioHeight
    : this.ratioHeight / this.ratioWidth; // A4 format
  @Input() resizeToWidth: number = 805;
  @Input() cropPreviewWidth: string = '200px';
  @Input() _imagebase64_list: string[] = [];
  @Output() imagebase64: EventEmitter<string | string[]> = new EventEmitter<
    string | string[]
  >();

  imageChangedEvent: any = '';
  croppedImage: any = '';
  cropperReady = false;
  stillUploadable: number = this.UploadMax;

  /* pdf-viewer data */
  @ViewChild('firstFileInput', { static: true }) firstFileInput: ElementRef;
  @ViewChild('continueFileInput', { static: true }) continueFileInput: ElementRef;
  @ViewChild('pdfViewer', { static: true }) pdfViewer: PdfViewerComponent;
  @ViewChild('pdfViewerWrapper', { static: true }) pdfViewerWrapper: ElementRef;
  @ViewChild('cropperDialog', { static: true }) cropperDialog: TemplateRef<any>;
  @ViewChild('imageDirectionDialog', { static: true }) imageDirectionDialog: TemplateRef<any>;

  reader: FileReader = new FileReader();
  pdfCanvases: HTMLCanvasElement[] = [];
  pdfSrc: string = '';
  pdfPagesNumber: number = 0;
  pdfAsImages: string[] = [];
  renderedPdfCount: number = 0;
  // uploadedFileType: string = '';
  isDragScrollVisible: boolean = false;
  fileSizeLimit: number = 5000000;

  pdfUploadStatus: number = 0;
  isBarVisible: boolean = false;
  isPdfViewerVisible: boolean = true;

  constructor(
    private waffletranslateservice: WaffleTranslateService,
    private dialogService: NbDialogService,
  ) { }

  ngOnInit() {
    this.UploadMax = this.UploadMax < 1 ? 1 : this.UploadMax;
    this.dragScrollVisibility();
    this.assignImageValues();
  }

  changeDirection(value: boolean) {
    this.isDirectionVertical = value;
    this.assignImageValues();
    this.resizeToWidth = this.isDirectionVertical
      ? 805
      : (805 * this.ratioHeight) / this.ratioWidth;
  }

  assignImageValues() {
    this.width = this.isDirectionVertical ? '315px' : '445.5px';
    this.height = this.isDirectionVertical ? '445.5px' : '315px';
    this.aspectRatio = this.isDirectionVertical
      ? this.ratioWidth / this.ratioHeight
      : this.ratioHeight / this.ratioWidth;
  }

  fileChangeEvent(event: any, ref): void {
    if (ref) ref.close();
    if (event) {
      this.cropperReady = false;
      if (event.target.files[0]) {
        if (event.target.files[0].type === 'application/pdf') {
          this.handlePdfUpload();
        } else {
          this.imageChangedEvent = event;
          this.openCropperDialog(this.cropperDialog);
        }
      }
      this.dragScrollVisibility();
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.dragScrollVisibility();
  }

  imageLoaded() {
    this.cropperReady = true;
  }

  loadImageFailed() {
    this.waffletranslateservice.toastrDanger(`Load failed`, extractTranslateKey.ImagePdfUploader.ToastMsg.loadImageFailed);
  }

  imageCroppedCheck(ref) {
    this._imagebase64_list.push(this.croppedImage);
    this.imagebase64.emit(this._imagebase64_list);

    this.cropperReady = false;
    this.stillUploadable = this.UploadMax - this._imagebase64_list.length;
    this.dragScrollVisibility();
    ref.close();
    this.refreshInput();
  }

  imageCroppedCancel(ref) {
    this.cropperReady = false;
    if (this._imagebase64_list.length < 1) {
      this.croppedImage = '';
    }
    ref.close();
  }

  delete(_deleteitem_index) {
    this._imagebase64_list.splice(_deleteitem_index, 1);

    if (this._imagebase64_list.length < 1) {
      this.croppedImage = '';
      this.imagebase64.emit(this.croppedImage);
    }
    this.imagebase64.emit(this._imagebase64_list);
    this.stillUploadable = this.UploadMax - this._imagebase64_list.length;
    this.dragScrollVisibility();
  }

  handlePdfUpload() {
    if (typeof FileReader !== 'undefined') {
      this.reader.onload = (event: any) => {
        this.pdfSrc = event.target.result;
      };

      const fileInput = this.firstFileInput || this.continueFileInput;
      const file = fileInput.nativeElement.files[0];
      const fileSize: number = fileInput.nativeElement.files[0].size;

      /* IF FILESIZE OK, GO ON; ELSE ALERT */
      if (fileSize <= this.fileSizeLimit) {
        this.reader.readAsArrayBuffer(file);
      } else {
        this.waffletranslateservice.toastrDanger(
          `ERROR: The pdf file size is too big`,
          { key: extractTranslateKey.ImagePdfUploader.ToastMsg.handlePdfUpload, interpolateParams: { 'size': this.fileSizeLimit / 1000000 } },
        );
      }
    }
  }

  onLoadComplete($event) {
    this.pdfPagesNumber = $event.numPages;
    this.renderedPdfCount = 0;
    this.isBarVisible = true;
  }

  onPageRendered() {
    if (this.pdfPagesNumber <= this.UploadMax) {
      this.pdfCanvases = Array.from(
        this.pdfViewerWrapper.nativeElement.querySelectorAll('canvas'),
      );
      this.generateImageFromPdf();
    } else if (
      this.pdfPagesNumber > this.UploadMax &&
      this.renderedPdfCount < 1
    ) {
      this.isBarVisible = false;
      this.waffletranslateservice.toastrDanger(
        `ERROR: The pdf file has too many pages`,
        { key: extractTranslateKey.ImagePdfUploader.ToastMsg.onPageRendered, interpolateParams: { 'UploadMax': this.UploadMax } },
      );
      // this.isPdfViewerVisible = false;
      this.pdfViewer.ngOnDestroy();
    }

    this.renderedPdfCount++;
    this.pdfUploadStatus = (100 * this.renderedPdfCount) / this.pdfPagesNumber;

    if (this.renderedPdfCount === this.pdfPagesNumber) {
      setTimeout(() => {
        this.pdfUploadStatus = 0;
        this.isBarVisible = false;
      }, 2500);
    }
  }

  get status() {
    if (this.pdfUploadStatus < 100) {
      return 'primary';
    } else {
      return 'success';
    }
  }

  generateImageFromPdf() {
    if (this.pdfCanvases.length === this.pdfPagesNumber) {
      let _pdfArray: string[] = [];

      this.pdfCanvases.forEach((canvas: HTMLCanvasElement) => {
        this.pdfAsImages = [
          ...this.pdfAsImages,
          canvas.toDataURL('image/jpeg', 1.0),
        ];
        _pdfArray = [..._pdfArray, canvas.toDataURL('image/jpeg', 1.0)];
      });
      // this._imagebase64_list = _pdfArray.concat(this._imagebase64_list);
      this._imagebase64_list = this._imagebase64_list.concat(_pdfArray);
      this.imagebase64.emit(this._imagebase64_list);
      this.stillUploadable = this.UploadMax - this._imagebase64_list.length;
      this.dragScrollVisibility();
      this.refreshInput();
    }
  }

  dragScrollVisibility() {
    this.isDragScrollVisible =
      (this._imagebase64_list.length > 0 &&
        !this.cropperReady &&
        this.croppedImage !== '') ||
      !!this._imagebase64_list.length;

    this.stillUploadable = this.UploadMax - this._imagebase64_list.length;
  }

  onError(error) {
    if (error.code === 1) {
      this.waffletranslateservice.toastrDanger(
        `Unable to upload PDF document because protected by password`,
        extractTranslateKey.ImagePdfUploader.ToastMsg.onErrorCodeOne,
      );
    }
  }

  refreshInput() {
    if (this.firstFileInput) {
      this.firstFileInput.nativeElement.value = null;
    }
    if (this.continueFileInput) {
      this.continueFileInput.nativeElement.value = null;
    }
  }

  openCropperDialog(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, {
      context: {},
      closeOnBackdropClick: false,
    });
  }

  openDirectionDialog(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, {
      context: {},
      closeOnBackdropClick: false,
    });
  }
}
