import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'wf-info-popover',
  templateUrl: './info-popover.component.html',
  styleUrls: ['./info-popover.component.scss'],
})
export class WaffleInfoPopoverComponent implements OnInit {
  @Input() fontSize: string = '1.2rem';
  @Input() content: string = 'NO CONTENT PROVIDED';

  constructor() { }

  ngOnInit() {
  }

}
