import { Component, OnInit, ViewChild, OnDestroy, Input, Output, AfterViewInit, EventEmitter } from '@angular/core';

export interface Config {
  size: 'qvga' | 'vga' | 'hd' | 'fullhd' | 'fourK' | 'eightK';
  maxImages: number;
  outputSize: 'qvga' | 'vga' | 'hd' | 'fullhd' | 'fourK' | 'eightK';
}

export interface ErrorMsg {
  title: string;
  msg: string;
}

@Component({
  selector: 'wf-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss'],
})
export class CameraComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('hardwareVideo', { static: true }) hardwareVideo: any;
  @Input() config: Config = {
    size: 'qvga',
    maxImages: 10,
    outputSize: 'qvga',
  };
  @Input() showResolutionBar: boolean = false;
  @Output() imagesArray: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();

  private _navigator;
  localStream;
  video;
  images: Array<string> = [];
  videoSelect: Array<{ id: string, name: string }> = [];
  videoSelected: string;
  devicesError: ErrorMsg;
  resolutionArray = ['qvga', 'vga', 'hd', 'fullhd', 'fourK', 'eightK'];
  videoSize = {
    width: 320,
    height: 240,
  };

  ngOnInit() { }

  ngAfterViewInit() {
    this.video = this.hardwareVideo.nativeElement;
    this._navigator = <any>navigator;

    this._navigator.getUserMedia = (this._navigator.getUserMedia || this._navigator.webkitGetUserMedia
      || this._navigator.mozGetUserMedia || this._navigator.msGetUserMedia);

    this.getPermission();
  }

  ngOnDestroy() {
    if (this.localStream) this.stopStream();
  }

  async getPermission() {
    await this._navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
      for (const track of stream.getTracks()) {
        track.stop();
      }
      this.enumerateDevices();
    }).catch(e => {
      this.devicesError = {
        title: '找不到相機或無相機權限',
        msg: `${e.name}: ${e.constraint}(${e.message})`,
      };
      console.error(e);
    });
  }

  stopStream() {
    if (!this.localStream) return;
    const tracks = this.localStream.getTracks();
    for (const track of tracks) {
      track.stop();
    }
    this.localStream = null;
    this.video.srcObject = null;
  }

  async startStream() {
    if (this.videoSelected === '') return;
    if (this.localStream) this.stopStream();
    const videoSource = this.videoSelected;
    const constraints = {
      video: {
        deviceId: videoSource ? { exact: videoSource } : undefined,
        width: { exact: this.videoSize.width },
        height: { exact: this.videoSize.height },
      },
      audio: false,
    };
    await this._navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      this.localStream = stream;
      this.video.srcObject = stream;
      this.video.play();
    }).catch(e => {
      this.devicesError = {
        title: '使用媒體輸入失敗',
        msg: `${e.name}: ${e.constraint}(${e.message})`,
      };
      console.error(e);
    });
  }

  takeShot() {
    if (this.images.length >= this.config.maxImages) return;
    const _canvasRef = document.createElement('canvas');
    const _temp_resolution = this.getResolution(this.config.outputSize);
    _canvasRef.width = _temp_resolution.width;
    _canvasRef.height = _temp_resolution.height;
    const _cxt: CanvasRenderingContext2D = _canvasRef.getContext('2d');
    _cxt.drawImage(this.video, 0, 0, _canvasRef.width, _canvasRef.height);
    this.images.push(_canvasRef.toDataURL());
    this.imagesArray.emit(this.images);
  }

  removeImages(_index: number) {
    this.images.splice(_index, 1);
    this.imagesArray.emit(this.images);
  }

  gotDevices(deviceInfos: any) {
    this.videoSelect = [];
    for (const item of deviceInfos) {
      if (item.kind === 'videoinput') {
        this.videoSelect.push({
          id: item.deviceId,
          name: item.label || `camera${this.videoSelect.length}`,
        });
      }
    }
  }

  enumerateDevices() {
    this._navigator.mediaDevices.enumerateDevices().then(e => {
      this.gotDevices(e);
      if (this.videoSelect.length > 0) {
        this.videoSelected = this.videoSelect[0].id;
        this.changeResolution(this.config.size);
      }
    }).catch(e => {
      this.devicesError = {
        title: '請求媒體輸入輸出列表失敗',
        msg: `${e.name}: ${e.constraint}(${e.message})`,
      };
      console.error(e);
    });
  }

  changeResolution(resolution: '-1' | 'qvga' | 'vga' | 'hd' | 'fullhd' | 'fourK' | 'eightK') {
    if (resolution === '-1') return;
    this.config.size = resolution;
    const _temp_resolution = this.getResolution(resolution);
    this.videoSize.width = _temp_resolution.width;
    this.videoSize.height = _temp_resolution.height;
    this.devicesError = null;
    this.startStream();
  }

  onClickChooseCamera(_deviceId: string) {
    this.videoSelected = _deviceId;
    this.startStream();
  }

  onClickCloseCamera() {
    this.videoSelected = '';
    this.stopStream();
  }

  getResolution(resolution: 'qvga' | 'vga' | 'hd' | 'fullhd' | 'fourK' | 'eightK') {
    let _width: number;
    let _height: number;
    switch (resolution) {
      case 'qvga':
        _width = 320;
        _height = 240;
        break;
      case 'vga':
        _width = 640;
        _height = 480;
        break;
      case 'hd':
        _width = 1280;
        _height = 720;
        break;
      case 'fullhd':
        _width = 1920;
        _height = 1080;
        break;
      case 'fourK':
        _width = 4096;
        _height = 2160;
        break;
      case 'eightK':
        _width = 7680;
        _height = 4320;
        break;
      default:
        console.error(`not match resolution`);
        _width = 320;
        _height = 240;
        break;
    }
    return {
      width: _width,
      height: _height,
    };
  }

}
