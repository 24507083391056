import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { WaffleToastrService } from '../../../@core/data/waffle-toastr.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Image } from '../../../@core/data/utilities.service';

@Component({
  selector: 'ngx-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent implements OnInit, OnChanges {

  @Input() Multiple: boolean = false;
  @Input() UploadMax: number = 1;
  @Input() height: string = '225px';
  @Input() width: string = '400px';
  @Input() displayImageWidth: string = '50%';
  @Input() aspectRatio: number = (16 / 9);
  @Input() resizeToWidth: number = 0;
  @Input() cropPreviewWidth: string = '100%';
  @Input() _imagebase64_list: Array<Image | string> = [];
  @Output() imagebase64: EventEmitter<Image | string | Array<Image | string>> = new EventEmitter<string | Array<Image | string>>();
  @Input() shadowIsActive: boolean = false;

  imageChangedEvent: any = '';
  croppedImage: string = '';
  cropperReady = false;
  // 單圖輸入
  _imagebase64: Image | string;
  IMAGE_SIZE_LIMIT: number = 4194304;
  IMAGE_LENGTH_LIMIT: number = 2048;
  imageError: boolean = false;

  constructor(
    private waffletoastrservice: WaffleToastrService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.UploadMax = (this.UploadMax < 1) ? 1 : this.UploadMax;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['_imagebase64_list'] && changes['_imagebase64_list'].currentValue.length > 0) {
      this._imagebase64_list = [...changes['_imagebase64_list'].currentValue];
      if (this.Multiple) {
        this.imagebase64.emit(this._imagebase64_list);
      } else {
        this._imagebase64 = this._imagebase64_list[0];
        this.imagebase64.emit(this._imagebase64);
      }
    }
  }

  fileChangeEvent(event: any): void {
    this.imageError = false;
    const [file] = event.target.files;
    if (file.size > this.IMAGE_SIZE_LIMIT) {
      this.imageError = true;
      this.waffletoastrservice.showDanger(`error`, `${this.translateService.instant('ImageCropper.ToastMsg.ImageSizeTooBigError')} ${this.bytesToSize(this.IMAGE_SIZE_LIMIT)}`);
    } else {
      this.cropperReady = false;
      this.imageChangedEvent = event;
    }
  }

  bytesToSize(bytes, seperator = '') {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))), 10);
    if (i === 0) return `${bytes}${seperator}${sizes[i]}`;
    return `${(bytes / (1024 ** i)).toFixed(1)}${seperator}${sizes[i]}`;
  }

  imageCropped(event: ImageCroppedEvent) {
    const { width, height } = event;
    if (width > this.IMAGE_LENGTH_LIMIT || height > this.IMAGE_LENGTH_LIMIT) {
      this.waffletoastrservice.showDanger(`error`, `${this.translateService.instant('ImageCropper.ToastMsg.ImageLengthTooBigError')} ${this.IMAGE_LENGTH_LIMIT} px`);
      this.imageCropedCancel();
      this.imageError = true;
    } else {
      this.croppedImage = event.base64;
    }
  }

  imageLoaded() {
    this.cropperReady = true;
  }

  loadImageFailed() {
    this.waffletoastrservice.showDanger(`error`, `ImageCropper.ToastMsg.loadImageFailed`);
  }

  imageCropedCheck() {
    if (this.Multiple) {
      this._imagebase64_list = [this.croppedImage, ...this._imagebase64_list];
      this.imagebase64.emit(this._imagebase64_list);
    } else {
      this._imagebase64 = this.croppedImage;
      this.imagebase64.emit(this._imagebase64);
    }
    this.cropperReady = false;
  }

  imageCropedCancel() {
    this.cropperReady = false;
    this.croppedImage = '';
  }

  delete(item) {
    const _deleteitem_index = this._imagebase64_list.indexOf(item);
    this._imagebase64_list.splice(_deleteitem_index, 1);
    this.imagebase64.emit(this._imagebase64_list);
  }

  refresh() {
    this.croppedImage = '';
    this._imagebase64_list.length = 0;
  }
}
