import { Component, OnInit, HostListener, Input, OnChanges, SimpleChange, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { AlertDialogComponent } from '../../components/alert-dialog/alert-dialog.component';
import { UtilitiesService } from '../../../@core/data/utilities.service';

export interface WeekBusinessHoursTable {
  rowname: string;
  rows: Array<WeekBusinessHoursTableCols>;
}
export interface WeekBusinessHoursTableCols {
  position: WeekBusinessHoursTablePosition;
  hover: boolean;
  selected: boolean;
}
export interface WeekBusinessHoursTablePosition {
  row: number;
  col: number;
}

export interface OutputData {
  timeblock: number;
  WeekBusinessHours: Array<WeekBusinessHours>;
}

export interface WeekBusinessHours {
  enabled: boolean;
  hours: Array<HoursOfWeekBusinessHours>;
}

export interface HoursOfWeekBusinessHours {
  open: string;
  close: string;
}

export interface IndexOutputData {
  timeblock: number;
  WeekBusinessHours: Array<IndexWeekBusinessHours>;
}

export interface IndexWeekBusinessHours {
  enabled: boolean;
  hours: Array<number>;
}


@Component({
  selector: 'ngx-waffle-week-time-list',
  templateUrl: './week-time-list.component.html',
  styleUrls: ['./week-time-list.component.scss'],
})
export class WeekTimeListComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() WeekBussinessHours: OutputData;
  @Input() IndexWeekBussinessHours: IndexOutputData;

  @ViewChild('table_tbody', { static: true }) private _table_tbody: ElementRef;

  public week_business_hours_table: Array<WeekBusinessHoursTable> = [];
  private week_business_hours_table_mousedown: boolean = false;
  private week_business_hours_table_start_position: WeekBusinessHoursTablePosition;
  private week_business_hours_table_end_position: WeekBusinessHoursTablePosition;
  private week_business_hours_table_max_position: WeekBusinessHoursTablePosition;
  public table_selected_mode: boolean = true;
  public TimeBlock: number = 60;
  private Block: number;
  public OutputData: OutputData;
  public IndexOutputData: IndexOutputData;

  constructor(protected ref: NbDialogRef<WeekTimeListComponent>,
    private dialogservice: NbDialogService,
    private utilitiesservice: UtilitiesService) { }

  ngOnInit() {
    this.HasWeekBussinessHoursTable();
  }

  public ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    this.HasWeekBussinessHoursTable();
  }

  ngAfterViewInit() {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    try {
      this._table_tbody.nativeElement.scrollTop = this._table_tbody.nativeElement.scrollHeight;
    } catch (err) { }
  }

  HasWeekBussinessHoursTable() {
    if (this.WeekBussinessHours) {
      if (this.WeekBussinessHours.timeblock !== 0) this.TimeBlock = this.WeekBussinessHours.timeblock;
      let _temp = false;
      for (const item of this.WeekBussinessHours.WeekBusinessHours) {
        _temp = _temp || item.enabled;
      }
      if (_temp) {
        this.WeekBussinessHoursTableCreate();
      } else {
        this.TableCreate();
      }
    } else if (this.IndexWeekBussinessHours) {
      if (this.IndexWeekBussinessHours.timeblock !== 0) this.TimeBlock = this.IndexWeekBussinessHours.timeblock;
      let _temp = false;
      for (const item of this.IndexWeekBussinessHours.WeekBusinessHours) {
        _temp = _temp || item.enabled;
      }
      if (_temp) {
        this.IndexWeekBussinessHoursTableCreate();
      } else {
        this.TableCreate();
      }
    }
  }

  TableCreate() {
    if (!(typeof this.TimeBlock === 'number' && (this.TimeBlock === 30 || this.TimeBlock === 60 || this.TimeBlock === 120 || this.TimeBlock === 240))) {
      this.TimeBlock = 30;
    }
    this.Block = (this.TimeBlock === 30) ? 48 : 24;
    this.week_business_hours_table = [];
    for (let row = 0; row < this.Block; row++) {
      const _temp = new Array<WeekBusinessHoursTableCols>();
      for (let col = 0; col < 7; col++) {
        _temp.push({
          position: {
            row: row,
            col: col,
          },
          hover: false,
          selected: false,
        });
      }
      this.week_business_hours_table.push({
        rowname: `${this.IndexToMomentHHmm(row, 'begin')} ~ ${this.IndexToMomentHHmm(row + 1, 'end')}`,
        rows: _temp,
      });
    }
  }

  WeekBussinessHoursTableCreate() {
    if (!(typeof this.TimeBlock === 'number' && (this.TimeBlock === 30 || this.TimeBlock === 60 || this.TimeBlock === 120 || this.TimeBlock === 240))) {
      this.TimeBlock = 30;
    }
    this.Block = (this.TimeBlock === 30) ? 48 : 24;
    this.week_business_hours_table = [];
    for (let row = 0; row < this.Block; row++) {
      const _temp = new Array<WeekBusinessHoursTableCols>();
      for (let col = 0; col < 7; col++) {
        _temp.push({
          position: {
            row: row,
            col: col,
          },
          hover: false,
          selected: this.TimeToTablePosition(col, row),
        });
      }
      //
      this.week_business_hours_table.push({
        rowname: `${this.IndexToMomentHHmm(row, 'begin')} ~ ${this.IndexToMomentHHmm(row + 1, 'end')}`,
        rows: _temp,
      });
    }
  }

  IndexToMomentHHmm(index: number, position: 'begin' | 'end') {
    return this.utilitiesservice.TimeIndexToTimeText(index, position);
  }

  IndexWeekBussinessHoursTableCreate() {
    if (!(typeof this.TimeBlock === 'number' && (this.TimeBlock === 30 || this.TimeBlock === 60 || this.TimeBlock === 120 || this.TimeBlock === 240))) {
      this.TimeBlock = 30;
    }
    this.Block = (this.TimeBlock === 30) ? 48 : 24;
    this.week_business_hours_table = [];
    for (let row = 0; row < this.Block; row++) {
      const _temp = new Array<WeekBusinessHoursTableCols>();
      for (let col = 0; col < 7; col++) {
        _temp.push({
          position: {
            row: row,
            col: col,
          },
          hover: false,
          selected: this.IndexTimeToTablePosition(col, row),
        });
      }
      this.week_business_hours_table.push({
        rowname: `${this.IndexToMomentHHmm(row, 'begin')} ~ ${this.IndexToMomentHHmm(row + 1, 'end')}`,
        rows: _temp,
      });
    }
  }

  @HostListener('mouseup') Mouseup() {
    this.week_business_hours_table_mousedown = false;
    if (this.week_business_hours_table_max_position) {
      this.TableEventAreaHover(false);
      this.TableEventAreaFixedChange();
      this.week_business_hours_table_max_position = undefined;
    }
  }

  TableMouseOver(rows: WeekBusinessHoursTableCols, event) {
    if (this.week_business_hours_table_mousedown) {
      this.week_business_hours_table_end_position = rows.position;
      if (
        this.week_business_hours_table_max_position &&
        (
          this.week_business_hours_table_max_position.row >= rows.position.row ||
          this.week_business_hours_table_max_position.col >= rows.position.col
        )
      ) {
        this.TableEventAreaHover(false);
      }
      this.week_business_hours_table_max_position = rows.position;
      this.TableEventAreaHover(true);
    }
    event.preventDefault();
  }

  TableMouseDown(rows: WeekBusinessHoursTableCols, event) {
    this.TableEventAreaHover(false);
    this.week_business_hours_table_mousedown = true;
    this.week_business_hours_table_start_position = rows.position;
    this.TableMouseOver(rows, event);
    event.preventDefault();
  }

  TableEventAreaHover(usemode: boolean) {
    if (this.week_business_hours_table_start_position && this.week_business_hours_table_max_position) {
      const s_p_row = this.week_business_hours_table_start_position.row;
      const s_p_col = this.week_business_hours_table_start_position.col;
      const max_p_row = this.week_business_hours_table_max_position.row;
      const max_p_col = this.week_business_hours_table_max_position.col;
      let _temp_table_col;
      for (let row = s_p_row; row <= max_p_row; row++) {
        for (let col = s_p_col; col <= max_p_col; col++) {
          _temp_table_col = this.week_business_hours_table[row].rows[col];
          _temp_table_col.hover = usemode;
        }
      }
    }
  }

  TableEventAreaFixedChange() {
    // 20180107 將取消模式拿掉 'TableCancelMode()' 'this.table_selected_mode'
    // 取代為'只有選一格時，即取消'
    if (this.week_business_hours_table_start_position) {
      const s_p_row = this.week_business_hours_table_start_position.row;
      const s_p_col = this.week_business_hours_table_start_position.col;
      const e_p_row = this.week_business_hours_table_end_position.row;
      const e_p_col = this.week_business_hours_table_end_position.col;
      let _temp_table_col;
      if (s_p_row === e_p_row && s_p_col === e_p_col) { // 只有選一格時，即取消
        _temp_table_col = this.week_business_hours_table[s_p_row].rows[s_p_col];
        _temp_table_col.selected = !_temp_table_col.selected;
      } else { // 正常計算填滿模式
        for (let row = s_p_row; row <= e_p_row; row++) {
          for (let col = s_p_col; col <= e_p_col; col++) {
            _temp_table_col = this.week_business_hours_table[row].rows[col];
            _temp_table_col.selected = this.table_selected_mode;
          }
        }
      }
    }
  }

  getTimeLine() {
    this.OutputData = {
      timeblock: this.TimeBlock,
      WeekBusinessHours: [
        {
          enabled: false,
          hours: [],
        },
        {
          enabled: false,
          hours: [],
        },
        {
          enabled: false,
          hours: [],
        },
        {
          enabled: false,
          hours: [],
        },
        {
          enabled: false,
          hours: [],
        },
        {
          enabled: false,
          hours: [],
        },
        {
          enabled: false,
          hours: [],
        },
      ],
    };
    let pre_position_row: number;
    for (let const_col = 0; const_col < 7; const_col++) {
      pre_position_row = -1;
      for (let table_rows = 0; table_rows < this.week_business_hours_table.length; table_rows++) {
        const _temp_table = this.week_business_hours_table;
        if (pre_position_row === -1 && _temp_table[table_rows].rows[const_col].selected) {
          pre_position_row = _temp_table[table_rows].rows[const_col].position.row;
        }

        if (pre_position_row !== -1 && !_temp_table[table_rows].rows[const_col].selected) {
          this.OutputData.WeekBusinessHours[const_col].hours.push({
            open: `${this.IndexToMomentHHmm(_temp_table[pre_position_row].rows[const_col].position.row, 'begin')}`,
            close: `${this.IndexToMomentHHmm(_temp_table[table_rows - 1].rows[const_col].position.row, 'end')}`,
          });
          pre_position_row = -1;
        }
      }
      if (pre_position_row !== -1 && this.week_business_hours_table[this.week_business_hours_table.length - 1].rows[const_col].selected) {
        this.OutputData.WeekBusinessHours[const_col].hours.push({
          open: `${this.IndexToMomentHHmm(this.week_business_hours_table[pre_position_row].rows[const_col].position.row, 'begin')}`,
          close: `${this.IndexToMomentHHmm(this.week_business_hours_table[this.week_business_hours_table.length - 1].rows[const_col].position.row, 'end')}`,
        });
        pre_position_row = -1;
      }
      this.OutputData.WeekBusinessHours[const_col].enabled = this.OutputData.WeekBusinessHours[const_col].hours.length > 0;
    }
  }

  getIndexTimeLine() {
    this.IndexOutputData = {
      timeblock: this.TimeBlock,
      WeekBusinessHours: [
        {
          enabled: false,
          hours: [],
        },
        {
          enabled: false,
          hours: [],
        },
        {
          enabled: false,
          hours: [],
        },
        {
          enabled: false,
          hours: [],
        },
        {
          enabled: false,
          hours: [],
        },
        {
          enabled: false,
          hours: [],
        },
        {
          enabled: false,
          hours: [],
        },
      ],
    };
    let pre_position_row: number;
    for (let const_col = 0; const_col < 7; const_col++) {
      pre_position_row = -1;
      for (let table_rows = 0; table_rows < this.week_business_hours_table.length; table_rows++) {
        const _temp_table = this.week_business_hours_table;
        if (pre_position_row === -1 && _temp_table[table_rows].rows[const_col].selected) {
          pre_position_row = _temp_table[table_rows].rows[const_col].position.row;
        }

        if (pre_position_row !== -1 && !_temp_table[table_rows].rows[const_col].selected) {
          for (let _i = pre_position_row; _i < table_rows; _i++) {
            this.IndexOutputData.WeekBusinessHours[const_col].hours.push(_i);
          }
          pre_position_row = -1;
        }
      }
      if (pre_position_row !== -1 && this.week_business_hours_table[this.week_business_hours_table.length - 1].rows[const_col].selected) {
        for (let _i = pre_position_row; _i < this.week_business_hours_table.length; _i++) {
          this.IndexOutputData.WeekBusinessHours[const_col].hours.push(_i);
        }
        pre_position_row = -1;
      }
      this.IndexOutputData.WeekBusinessHours[const_col].enabled = this.IndexOutputData.WeekBusinessHours[const_col].hours.length > 0;
    }
  }

  TableCancelMode() {
    this.table_selected_mode = !this.table_selected_mode;
  }

  TableReset() {
    this.dialogservice.open(AlertDialogComponent, {
      context: {
        title: 'Warning',
        content: 'WeekTimeListTableReset',
        mode: 'dangerConfirm',
        submitButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      },
    }).onClose.subscribe(data => {
      if (data && this.week_business_hours_table) {
        for (const row of this.week_business_hours_table) {
          if (row.rows) {
            for (const col of row.rows) {
              col.selected = false;
            }
          }
        }
      }
    });
  }

  TimeToTablePosition(col: number, row: number) {
    if (this.WeekBussinessHours.timeblock === 30) {
      for (const hours of this.WeekBussinessHours.WeekBusinessHours[col].hours) {
        const open = this.TimeSplit(hours.open);
        const close = this.TimeSplit(hours.close);
        if (close.hour === 0 && close.minute === 0) close.hour = 24;
        if ((row / 2) >= (open.hour + (open.minute / 60)) && (row / 2) < (close.hour + (close.minute / 60))) return true;
      }
    } else {
      for (const hours of this.WeekBussinessHours.WeekBusinessHours[col].hours) {
        const open = this.TimeSplit(hours.open);
        const close = this.TimeSplit(hours.close);
        if (close.hour === 0 && close.minute === 0) close.hour = 24;
        if (row >= open.hour && row < close.hour) return true;
      }
    }
    return false;
  }

  IndexTimeToTablePosition(col: number, row: number) {
    const _temp = this.IndexWeekBussinessHours.WeekBusinessHours[col].hours.find(e => e === row);
    return (_temp === undefined) ? false : true;
  }

  TimeBlockChange(event) {
    if (this.TimeBlock !== Number(event.target.value)) {
      event.preventDefault();
      event.target.blur();
      this.dialogservice.open(AlertDialogComponent, {
        context: {
          title: 'Warning',
          content: 'WeekTimeListTimeBlockChange',
          mode: 'dangerConfirm',
          submitButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
        },
      }).onClose.subscribe(data => {
        if (data) {
          this.TimeBlock = Number(event.target.value);
          event.target.checked = true;
          this.TableCreate();
        }
      });
    }
  }

  TimeSplit(str: string) {
    const ser_arr = str.split(':');
    return { hour: Number(ser_arr[0]), minute: Number(ser_arr[1]) };
  }

  dismiss(response: boolean = false) {
    if (response) {
      this.getTimeLine();
      this.getIndexTimeLine();
    }
    this.ref.close({
      Time: this.OutputData,
      Index: this.IndexOutputData,
    });
  }
}
