import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NbToastrService } from '@nebular/theme';
import { environment } from '../../../environments/environment';
import { marker as extractTranslate } from '@biesbjerg/ngx-translate-extract-marker';

export class LangPara {
  key: string | string[];
  interpolateParams?: Object;
}

export const extractTranslateKey = {
  Primary: extractTranslate('Primary'),
  Success: extractTranslate('Success'),
  Info: extractTranslate('Info'),
  Warning: extractTranslate('Warning'),
  Danger: extractTranslate('Danger'),
  Error: extractTranslate('Error'),
  Community: {
    EditModeLeave: extractTranslate('Community.EditModeLeave'),
    ToastMsg: {
      QueryCommunityInfoFail: extractTranslate('Community.ToastMsg.QueryCommunityInfoFail'),
      QueryCommunityCommitteeFail: extractTranslate('Community.ToastMsg.QueryCommunityCommitteeFail'),
      AddCommitteeFail: extractTranslate('Community.ToastMsg.AddCommitteeFail'),
      AddCommitteeOrganizationsSuccess: extractTranslate('Community.ToastMsg.AddCommitteeOrganizationsSuccess'),
      AddCommitteeOrganizationsFail: extractTranslate('Community.ToastMsg.AddCommitteeOrganizationsFail'),
      EditCommitteeOrganizationsSuccess: extractTranslate('Community.ToastMsg.EditCommitteeOrganizationsSuccess'),
      EditCommitteeOrganizationsFail: extractTranslate('Community.ToastMsg.EditCommitteeOrganizationsFail'),
      DeleteCommitteeOrganizationsSuccess: extractTranslate('Community.ToastMsg.DeleteCommitteeOrganizationsSuccess'),
      DeleteCommitteeOrganizationsFail: extractTranslate('Community.ToastMsg.DeleteCommitteeOrganizationsFail'),
    },
  },
  Family: {
    ToastMsg: {
      QueryFamilyFail: extractTranslate('Family.ToastMsg.QueryFamilyFail'),
      QueryFamilyInfo: extractTranslate('Family.ToastMsg.QueryFamilyInfo'),
      EditFamilyFail: extractTranslate('Family.ToastMsg.EditFamilyFail'),
      QueryFamiliesQuery: extractTranslate('Family.ToastMsg.QueryFamiliesQuery'),
      patchFamiliesProprietaryPromiseFail: extractTranslate('Family.ToastMsg.patchFamiliesProprietaryPromiseFail'),
      patchFamiliesParkingPromiseFail: extractTranslate('Family.ToastMsg.patchFamiliesParkingPromiseFail'),
      patchFamiliesMembersPromiseFail: extractTranslate('Family.ToastMsg.patchFamiliesMembersPromiseFail'),
    },
  },
  User: {
    ToastMsg: {
      QueryUsersRoleManagerFail: extractTranslate('User.ToastMsg.QueryUsersRoleManagerFail'),
      QueryDeleteUsersGroupSuccess: extractTranslate('User.ToastMsg.QueryDeleteUsersGroupSuccess'),
      QueryDeleteUsersGroupFail: extractTranslate('User.ToastMsg.QueryDeleteUsersGroupFail'),
      QueryUserInfo: extractTranslate('User.ToastMsg.QueryUserInfo'),
      PatchUsersPasswordPromiseSuccess: extractTranslate('User.ToastMsg.PatchUsersPasswordPromiseSuccess'),
      PatchUsersPasswordPromiseFail: extractTranslate('User.ToastMsg.PatchUsersPasswordPromiseFail'),
    },
  },
  CurrentUser: {
    ToastMsg: {
      QueryProFileFailLogout: extractTranslate('CurrentUser.ToastMsg.QueryProFileFailLogout'),
      QueryProFileFail: extractTranslate('CurrentUser.ToastMsg.QueryProFileFail'),
    },
  },
  QrCodeCreater: {
    ToastMsg: {
      GeneratingQRcodeFail: extractTranslate('QrCodeCreater.ToastMsg.GeneratingQRcodeFail'),
    },
  },
  ImagePdfUploader: {
    ToastMsg: {
      loadImageFailed: extractTranslate('ImagePdfUploader.ToastMsg.loadImageFailed'),
      handlePdfUpload: extractTranslate('ImagePdfUploader.ToastMsg.handlePdfUpload'),
      onPageRendered: extractTranslate('ImagePdfUploader.ToastMsg.onPageRendered'),
      onErrorCodeOne: extractTranslate('ImagePdfUploader.ToastMsg.onErrorCodeOne'),
    },
  },
  LayoutLoadingWarning: extractTranslate('LayoutLoadingWarning'),
  LayoutLoadingDanger: extractTranslate('LayoutLoadingDanger'),
  Attendance: {
    ToastMsg: {
      PostShiftsLoginFail: extractTranslate('Attendance.ToastMsg.PostShiftsLoginFail'),
      PostShiftsAuthcodesFail: extractTranslate('Attendance.ToastMsg.PostShiftsAuthcodesFail'),
      PostShiftsInfoFail: extractTranslate('Attendance.ToastMsg.PostShiftsInfoFail'),
      ShiftsLogoutTitle: extractTranslate('Attendance.ToastMsg.ShiftsLogoutTitle'),
    },
  },
};

@Injectable()
export class WaffleTranslateService {

  preventDuplicatesDanger: string | string[] | LangPara = '';

  constructor(
    private translateservice: TranslateService,
    private toastrService: NbToastrService,
  ) { }

  public toastrPrimary(message: string | string[] | LangPara, title: string | string[] | LangPara) {
    const _title = (this.instanceOf(title)) ? this.instant(title.key, title.interpolateParams) : this.instant(title);
    const _message = (this.instanceOf(message)) ? this.instant(message.key, message.interpolateParams) : this.instant(message);
    this.toastrService.primary(
      _message,
      _title,
      { duration: 3000, icon: { icon: 'check', pack: 'font-awesome' } });
  }

  public toastrSuccess(message: string | string[] | LangPara, title: string | string[] | LangPara) {
    const _title = (this.instanceOf(title)) ? this.instant(title.key, title.interpolateParams) : this.instant(title);
    const _message = (this.instanceOf(message)) ? this.instant(message.key, message.interpolateParams) : this.instant(message);
    this.toastrService.success(
      _message,
      _title,
      { duration: 3000, icon: { icon: 'check', pack: 'font-awesome' } });
  }

  public toastrInfo(message: string | string[] | LangPara, title: string | string[] | LangPara) {
    const _title = (this.instanceOf(title)) ? this.instant(title.key, title.interpolateParams) : this.instant(title);
    const _message = (this.instanceOf(message)) ? this.instant(message.key, message.interpolateParams) : this.instant(message);
    this.toastrService.info(
      _message,
      _title,
      { duration: 3000, icon: { icon: 'check', pack: 'font-awesome' } });
  }

  public toastrWarning(message: string | string[] | LangPara, title: string | string[] | LangPara) {
    const _title = (this.instanceOf(title)) ? this.instant(title.key, title.interpolateParams) : this.instant(title);
    const _message = (this.instanceOf(message)) ? this.instant(message.key, message.interpolateParams) : this.instant(message);
    this.toastrService.warning(
      _message,
      _title,
      { duration: 10000, icon: { icon: 'times', pack: 'font-awesome' } });
  }

  public toastrDanger(message: string | string[] | LangPara, title: string | string[] | LangPara) {
    const _title = (this.instanceOf(title)) ? this.instant(title.key, title.interpolateParams) : this.instant(title);
    const _message = (this.instanceOf(message)) ? this.instant(message.key, message.interpolateParams) : this.instant(message);
    if (_title !== this.preventDuplicatesDanger) {
      if (environment.production) {
        this.toastrService.danger(
          '',
          _title,
          { duration: 0, icon: { icon: 'times', pack: 'font-awesome' } });
      } else {
        this.toastrService.danger(
          _message,
          _title,
          { duration: 0, icon: { icon: 'times', pack: 'font-awesome' } });
      }
    }
    this.preventDuplicatesDanger = _title;
    setTimeout(() => {
      this.preventDuplicatesDanger = '';
    }, 3000);
  }

  instant(key: string | string[], interpolateParams?: Object): string | string[] | any {
    return (interpolateParams) ? this.translateservice.instant(key, interpolateParams) : this.translateservice.instant(key);
  }

  instanceOf(object: any): object is LangPara {
    return (typeof object === 'string' || Array.isArray(object)) ? false : 'key' in object;
  }

}
