import { UserData } from '../../../@core/data/users';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbMenuItem, NbThemeService } from '@nebular/theme';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { NowUserProfile } from '../../../@core/data/waffle-currentuser.service';
import { Subscription } from 'rxjs';
import { NbDialogService } from '@nebular/theme';
import { LoginShiftComponent } from '../../../pages/dashboard/login-shift/login-shift.component';
import { WaffleAttendanceService, ShowMode } from '../../../@core/data/waffle-attendance.service';
import { LogoutShiftComponent } from '../../../pages/dashboard/logout-shift/logout-shift.component';
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
    {
      value: 'waffletheme',
      name: 'waffletheme',
    },
  ];
  currentTheme = 'default';

  attendanceShowModes = [
    {
      value: ShowMode.All,
    },
    {
      value: ShowMode.TimeBlock,
    },
  ];
  currentAttendanceShowMode = ShowMode.All;

  @Input() position = 'normal';

  user: NowUserProfile;
  userMenu = [];
  communityMenu: NbMenuItem[] = [];
  CommunityList: Array<{
    CommunityId: string;
    CommunityName: string;
    CommunityAddress: string;
    Role: string;
    Display: boolean;
  }>;
  CommunitySelected;
  NowCommunity_id: string = '';
  NowCommunity_role: string = '';
  NowCommunity_name: string = '';
  subscribe_NowProfile: Subscription;
  subscribe_NowCommunity: Subscription;
  subscribe_LangChange: Subscription;
  subscribe_roleguard_canactivate: Subscription;
  // Select Community Button
  selectcommunity_dropdown: boolean = false;
  selectcommunity_input: string;
  // Filter Area
  selectarea_select: string = '';

  constructor(private themeService: NbThemeService,
    private userService: UserData,
    private breakpointService: NbMediaBreakpointsService,
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    public translate: TranslateService,
    private dialogservice: NbDialogService,
    private wfattendanceservice: WaffleAttendanceService) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.subscribe_LangChange = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateUserItems();
    });
    this.translateUserItems();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  changeShowMode(mode: ShowMode) {
    this.wfattendanceservice.updateShowMode(mode);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  translateUserItems() {
    this.translate.get(['UserMenu.Logout'])
      .subscribe(translations => {
        this.userMenu = [
          { title: translations['UserMenu.Logout'], link: '/auth/logout', icon: { icon: 'sign-out-alt', pack: 'font-awesome' } },
        ];
      });
  }

  onLoginShift() {
    this.dialogservice.open(LoginShiftComponent, {
      closeOnBackdropClick: false,
    }).onClose.subscribe(e => {
      if (e) {
        this.wfattendanceservice.updateShiftLogin();
      }
    });
  }

  onLogoutShift() {
    this.dialogservice.open(LogoutShiftComponent, {
      closeOnBackdropClick: false,
    }).onClose.subscribe(e => {
      if (e) {
        this.wfattendanceservice.updateShiftLogin();
      }
    });
  }
}
