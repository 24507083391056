import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'wf-waffle-software-terms',
  templateUrl: './waffle-software-terms.component.html',
  styleUrls: ['./waffle-software-terms.component.scss'],
})
export class WaffleSoftwareTermsComponent implements OnInit {

  @Input() src: 'terms' | 'privacy';

  constructor(protected ref: NbDialogRef<WaffleSoftwareTermsComponent>) { }

  ngOnInit() {
  }

  dismiss(response: boolean = false) {
    this.ref.close(response);
  }

}
