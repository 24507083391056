import { Component, OnInit, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'wf-telescope',
  template: `
    <button nbButton shape="round" status="{{ status }}">
      <div class="">
        <i class="{{ iconClass }} icon"></i>
          <span class="content">
            {{ content }}
          </span>
      </div>
    </button>
  `,
  styleUrls: ['./waffle-telescopic-button.component.scss'],
})
export class WaffleTelescopicButtonComponent implements OnInit {

  @Input() iconClass: string = 'fal fa-times';
  @Input() content: string = 'content';
  @Input() status: string = 'primary';

  constructor(
  ) { }

  ngOnInit() {
  }

}
