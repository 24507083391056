import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

export type DialogType = 'confirm' | 'alert' | 'dangerConfirm';

@Component({
  selector: 'ngx-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent implements OnInit {
  @Input() title: string = '';
  @Input() content: string = '';
  @Input() content_ishtml: boolean = false;
  @Input() cancelButtonText: string = '';
  @Input() submitButtonText: string = '';
  @Input() cardStatus: string = '';
  @Input() cardAccent: string = '';
  // mode can alert or confirm
  @Input() mode: DialogType = 'alert';

  constructor(protected ref: NbDialogRef<AlertDialogComponent>) { }

  ngOnInit() {

  }

  dismiss(response: boolean = false) {
    this.ref.close(response);
  }
}
