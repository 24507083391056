import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import * as moment from 'moment';
import { UtilitiesService } from '../../../@core/data/utilities.service';

@Component({
  selector: 'wf-waffle-time-filter',
  templateUrl: './waffle-time-filter.component.html',
  styleUrls: ['./waffle-time-filter.component.scss'],
})
export class WaffleTimeFilterComponent implements OnInit {

  @Input() title: string = '';
  @Input() data: Array<any> = [];
  @Input() filter_item_name = '';
  private data_source: Array<any>;
  data_filtered: Array<any> = [];
  timeline: 'today' | 'yesterday' | 'threedaysago' | 'oneweekago' | 'morethanthreedays' | 'morethansevendays' | 'all' = 'all';
  filter_btn: Array<{
    id: 'today' | 'yesterday' | 'threedaysago' | 'oneweekago' | 'morethanthreedays' | 'morethansevendays' | 'all',
    key: string,
  }>;

  constructor(protected ref: NbDialogRef<WaffleTimeFilterComponent>,
    private utilitiesservice: UtilitiesService) { }

  ngOnInit() {
    this.data_source = [...this.data];
    this.filter_btn = [
      { id: 'today', key: 'TimeFilter.Today' },
      { id: 'yesterday', key: 'TimeFilter.Yesterday' },
      { id: 'threedaysago', key: 'TimeFilter.ThreeDaysAgo' },
      { id: 'oneweekago', key: 'TimeFilter.OneWeekAgo' },
      { id: 'morethanthreedays', key: 'TimeFilter.MoreThanThreeDays' },
      { id: 'morethansevendays', key: 'TimeFilter.MoreThanSevenDays' },
      { id: 'all', key: 'TimeFilter.All' },
    ];
    this.onFilter(this.timeline);
  }

  onFilter(id: 'today' | 'yesterday' | 'threedaysago' | 'oneweekago' | 'morethanthreedays' | 'morethansevendays' | 'all') {
    let _begin, _end;
    this.timeline = id;
    if (id !== 'all') {
      const _temp = this.utilitiesservice.getFilterTimeLine(id);
      _begin = _temp.begin;
      _end = _temp.end;
    }
    if (_begin && _end) {
      this.data_filtered = this.data_source.filter(e => {
        if (e[this.filter_item_name]) {
          const _temp = moment(e[this.filter_item_name]).unix();
          return _temp >= _begin && _temp <= _end;
        } else {
          return false;
        }
      });
    } else {
      this.data_filtered = [...this.data_source];
    }
  }

  dismiss(response: boolean = false) {
    if (response) {
      this.ref.close();
    } else {
      this.ref.close(this.data_filtered);
    }
  }

}
