import { Injectable } from '@angular/core';
import { LocalStorageService, CacheType } from './localstorage.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpClientService } from './httpclient.service';
import { environment } from '../../../environments/environment';
import { WaffleTranslateService, extractTranslateKey } from './waffle-translate.service';
import { BehaviorSubject } from 'rxjs';

export interface ShiftsLoginData {
  token: string;
}

export interface ShiftLoginList {
  token: string;
  info: ShiftsInfo;
}

export interface ShiftsAuthcodesData {
  code: string;
  expired_at: string;
}

export interface ShiftsInfo {
  shift_id: string;
  username: string;
  password: string;
  name: string;
  description: string;
  shift_type: 'Fixed' | 'Dynamic';
  shift_code: string;
  day_hours: number[];
  timeclock: {
    type_qrcode: {
      expired_minutes: number;
    };
    type_image: {
      counts: number;
    };
    alert: {
      notify_roles: string[];
      check_times: string[];
      notify_emails: string[];
    },
    types: ShiftsAuthType[],
    type_wifi: TypeWifi[];
    type_gps: TypeGps[];
    type_network: TypeNetwork[];
    logic: 'Or' | 'And';
    flex_checkin_before_minutes: number;
    flex_checkin_later_minutes: number;
    flex_checkout_before_minutes: number;
    flex_checkout_later_minutes: number;
  };
  create_at: string;
  update_at: string;
}

export enum ShiftsAuthType {
  FacialRecognation = 'FacialRecognation',
  QRcode = 'QRcode',
  GPS = 'GPS',
  WiFi = 'WiFi',
  Network = 'Network',
  Image = 'Image',
}

export interface TypeWifi {
  ssid: string;
  mac_address: string;
  description: string;
}

export interface TypeGps {
  address: string;
  latitude: string;
  longitude: string;
  distance: number;
}

export interface TypeNetwork {
  ipv4: string;
  ipv6: string;
  description: string;
}

export enum ShowMode {
  All = 'All',
  TimeBlock = 'TimeBlock',
}

@Injectable()
export class WaffleAttendanceService {

  baseEndpoint = `${environment.ApiEndPoint}/attendance`;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'my-auth-token',
    }),
    params: new HttpParams(),
  };

  private ShiftLogin$ = new BehaviorSubject<ShiftLoginList[]>([]);
  private ShiftLogin = this.ShiftLogin$.asObservable();

  private ShiftShowMode$ = new BehaviorSubject<ShowMode>(ShowMode.All);
  private ShiftShowMode = this.ShiftShowMode$.asObservable();

  constructor(private localstorageservice: LocalStorageService,
    private httpclientservice: HttpClientService,
    private wftranslateservice: WaffleTranslateService) { }

  /**
   * 取得已登入之班別
   *
   * @returns {string[]}
   * @memberof WaffleAttendanceService
   */
  getShiftLogin() {
    return this.ShiftLogin;
  }

  async updateShiftLogin() {
    let _temp = this.localstorageservice.getData(CacheType.AttendanceTokenMap);
    _temp = (_temp && Array.isArray(_temp)) ? _temp : [];
    const _output = [];
    for (const item of _temp) {
      _output.push({
        token: item,
        info: await this.postShiftsInfo(item).catch(() => Promise.resolve(null)),
      });
    }
    this.ShiftLogin$.next(_output);
  }

  updateShiftLogout(token: string) {
    const _token_map: string[] = this.localstorageservice.getData(CacheType.AttendanceTokenMap);
    if (_token_map && Array.isArray(_token_map)) {
      const _index = _token_map.indexOf(token);
      if (_index > -1) {
        _token_map.splice(_index, 1);
      }
    }
    this.localstorageservice.setData(CacheType.AttendanceTokenMap, _token_map);
    this.updateShiftLogin();
  }

  /**
   * 登入班別打卡帳號
   *
   * @returns
   * @memberof WaffleAttendanceService
   */
  postShiftsLogin(username: string, password: string) {
    return new Promise<ShiftsLoginData>((resolve, reject) => {
      this.httpOptions.params = new HttpParams();
      this.httpclientservice.postp<any>(`${this.baseEndpoint}/v1/shifts/login`, {
        username,
        password,
      }, this.httpOptions).then(data => {
        let _token_map: string[] = this.localstorageservice.getData(CacheType.AttendanceTokenMap);
        if (_token_map && Array.isArray(_token_map)) {
          _token_map.push(data.data.token);
        } else {
          _token_map = [data.data.token];
        }
        this.localstorageservice.setData(CacheType.AttendanceTokenMap, _token_map);
        resolve(data.data);
      }).catch(error => {
        this.wftranslateservice.toastrDanger(`Code: ${error.code} - Message: ${error.msg}`, extractTranslateKey.Attendance.ToastMsg.PostShiftsLoginFail);
        reject(error);
      });
    });
  }

  /**
   * 產生驗證碼
   *
   * @memberof WaffleAttendanceService
   */
  postShiftsAuthcodes(token) {
    return new Promise<ShiftsAuthcodesData>((resolve, reject) => {
      this.httpOptions.params = new HttpParams();
      this.httpclientservice.postp<any>(`${this.baseEndpoint}/v1/shifts/authcodes`, {}, this.setAuthorization(token, this.httpOptions), false).then(data => {
        resolve(data.data);
      }).catch(error => {
        this.wftranslateservice.toastrDanger(`Code: ${error.code} - Message: ${error.msg}`, extractTranslateKey.Attendance.ToastMsg.PostShiftsAuthcodesFail);
        reject(error);
      });
    });
  }

  /**
   * 取得班別資訊
   *
   * @memberof WaffleAttendanceService
   */
  postShiftsInfo(token: string) {
    return new Promise<ShiftsInfo>((resolve, reject) => {
      this.httpOptions.params = new HttpParams();
      this.httpclientservice.getp<any>(`${this.baseEndpoint}/v1/shifts/info`, this.setAuthorization(token, this.httpOptions), false).then(data => {
        resolve(data.data);
      }).catch(error => {
        this.wftranslateservice.toastrDanger(`Code: ${error.code} - Message: ${error.msg}`, extractTranslateKey.Attendance.ToastMsg.PostShiftsInfoFail);
        reject(error);
      });
    });
  }

  private setAuthorization(token: string, options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }) {
    if (options.headers instanceof HttpHeaders) {
      options.headers = options.headers.set('Authorization', token);
    } else {
      options.headers['Authorization'] = token;
    }
    return options;
  }

  getShowMode() {
    return this.ShiftShowMode;
  }

  updateShowMode(mode: ShowMode) {
    this.ShiftShowMode$.next(mode);
  }


}
