import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { tap } from 'rxjs/operators/tap';
import { NbAccessChecker } from '@nebular/security';
import { WaffleFunctionsService } from './@core/data/waffle-functions.service';
import { WaffleCurrentUserService } from './@core/data/waffle-currentuser.service';
import { Path } from './../config/access_control';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private authService: NbAuthService,
        private router: Router) {
    }

    canActivate() {
        return this.authService.isAuthenticated()
            .pipe(
                tap(authenticated => {
                    if (!authenticated) {
                        this.router.navigate(['auth/login']);
                    }
                }),
            );
    }
}

@Injectable()
export class RoleGuard implements CanActivate {

    constructor(private router: Router,
        private accesschecker: NbAccessChecker,
        private wafflefunctionsservice: WaffleFunctionsService,
        private wafflecurrentuserservice: WaffleCurrentUserService) {
    }

    FindRouteIsGuard(path: string) {
        let _permission = '', _resource = '', _function = '';
        if (Path[path]) {
            _permission = Path[path].permission;
            _resource = Path[path].resource;
            _function = Path[path].function;
        } else {
            _permission = 'none';
            _resource = 'none';
            _function = 'none';
        }
        return {
            permission: _permission,
            resource: _resource,
            function: _function,
        };
    }

    GetFunctionIsGuard(_permission: string, _function: string) {
        return new Promise<boolean>(async (resolve, reject) => {
            if (_permission === 'menu' || _function === 'work-board' || _function === 'package' || _function === 'fees' || _function === 'skip') resolve(true);
            let _temp_function = this.wafflefunctionsservice.FunctionKeyValue;
            if (JSON.stringify(_temp_function) === '{}') {
                await this.wafflefunctionsservice.UpdateFunctionsPromise().then().catch();
                _temp_function = this.wafflefunctionsservice.FunctionKeyValue;
            }
            let _temp_boolean = false;
            await new Promise((_resolve, _reject) => {
                this.wafflecurrentuserservice.getNowCommunity().subscribe(now_c_data => {
                    if (now_c_data.community_id !== '') {
                        for (const item of now_c_data.functions) {
                            if (_temp_function[item] && _temp_function[item].type.toLocaleLowerCase() === _function) {
                                _temp_boolean = true;
                                break;
                            }
                        }
                        _resolve();
                    }
                });
            }).then();
            resolve(_temp_boolean);
        });
    }

    // 載入頁面與Menu的權限設定在此
    // 如果看見404頁面或是都導入到waffle-dashboard時可以檢查是否有設定權限
    canActivate(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot) {
        let _permission = '', _resource = '', _function = '';
        const temp = this.FindRouteIsGuard(state.url);
        _permission = temp.permission;
        _resource = temp.resource;
        _function = temp.function;

        // 判斷目前使用社區是否有目前頁面的功能(functions)
        const _functions_promise = this.GetFunctionIsGuard(_permission, _function);

        return this.accesschecker.isGranted(_permission, _resource)
            .pipe(
                tap(async authenticated => {
                    let _functions;
                    await _functions_promise.then(e => {
                        _functions = e;
                    });
                    if (!authenticated || !_functions) {
                        this.router.navigate(['pages/overview']);
                    }
                }),
            );

    }
}
