import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'MomentFormat',
})
export class MomentFormatPipe implements PipeTransform {
  constructor() { }
  transform(value: any, args?: any): any {
    if (!value) return '';
    if (args) return moment(value).format(args);
    return moment(value).format('YYYY/MM/DD HH:mm');
  }

}

@Pipe({
  name: 'MomentFromNow',
})
export class MomentFromNowPipe implements PipeTransform {

  constructor(private translateservice: TranslateService) { }
  transform(value, args: boolean) {
    const lang = (this.translateservice.currentLang) ? this.translateservice.currentLang.replace('-', '') : 'en';
    moment.locale(lang);
    return moment(value).fromNow((typeof args === 'boolean') ? args : false);
  }
}
