<nb-card class="loading_dialog__card">
  <nb-card-body>
    <p *ngIf="loadingdata && loadingdata._number === 0 && loadingdata._total === 0;else textelse"
      class="text-center">
      {{ 'LoadingDialog.MakeReady' | translate }}
    </p>
    <ng-template #textelse>
      <p class="text-center">
        {{ loadingdata._number }} / {{ loadingdata._total }}
      </p>
    </ng-template>
    <nb-progress-bar *ngIf="loadingdata._total > 0;else progressbar"
      [value]="((loadingdata._number / loadingdata._total) | number:'1.2-2') * 100">
    </nb-progress-bar>
    <ng-template #progressbar>
    </ng-template>
  </nb-card-body>
</nb-card>
