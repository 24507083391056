import { Component, OnInit, OnDestroy} from '@angular/core';
import { WaffleAttendanceService, ShiftLoginList } from '../../../@core/data/waffle-attendance.service';
import { Subscription } from 'rxjs';
import { NbDialogService, NbDialogRef } from '@nebular/theme';
import { AlertDialogComponent } from '../../../@theme/components/alert-dialog/alert-dialog.component';
import { extractTranslateKey } from '../../../@core/data/waffle-translate.service';

@Component({
  selector: 'wf-logout-shift',
  templateUrl: './logout-shift.component.html',
  styleUrls: ['./logout-shift.component.scss'],
})
export class LogoutShiftComponent implements OnInit, OnDestroy {

  private subscription_getShiftLogin: Subscription;
  datas: ShiftLoginList[] = [];

  constructor(private wfattendanceservice: WaffleAttendanceService,
    private ref: NbDialogRef<LogoutShiftComponent>,
    private dialogservice: NbDialogService) { }

  ngOnInit() {
    this.getShiftLogin();
  }

  ngOnDestroy() {
    if (this.subscription_getShiftLogin) this.subscription_getShiftLogin.unsubscribe();
  }

  dismiss() {
    this.ref.close(false);
  }

  private getShiftLogin() {
    this.subscription_getShiftLogin = this.wfattendanceservice.getShiftLogin().subscribe(data => {
      this.datas = [...data];
    });
  }

  onLogout(token: string) {
    this.dialogservice.open(AlertDialogComponent, {
      context: {
        title: 'Warning',
        content: extractTranslateKey.Attendance.ToastMsg.ShiftsLogoutTitle as string,
        mode: 'dangerConfirm',
        submitButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      },
    }).onClose.subscribe(e => {
      if (e) {
        this.wfattendanceservice.updateShiftLogout(token);
      }
    });
  }

}
