import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpClientService } from './httpclient.service';
import { apiconfig, ApiConfig } from '../../../config/apiservice';
import { BehaviorSubject } from 'rxjs';
import { WaffleToastrService } from './waffle-toastr.service';

export interface Functions {
  function_id: string;
  version: string;
  name: string;
  type: string;
  vendor: string;
  enabled: boolean;
  create_at: string;
  update_at: string;
  values: string;
  configs: string;
}

@Injectable()
export class WaffleFunctionsService {

  private Functions$ = new BehaviorSubject<Array<Functions>>([]);
  private Functions = this.Functions$.asObservable();
  config: ApiConfig = apiconfig;
  token: string;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'my-auth-token',
    }),
    params: new HttpParams(),
  };

  FunctionKeyValue: { [Key: string]: Functions } = {};

  constructor(private httpclientservice: HttpClientService,
    private waffletoastrservice: WaffleToastrService) { }

  getFunctions() {
    return this.Functions;
  }

  UpdateFunctions() {
    if (JSON.stringify(this.FunctionKeyValue) === '{}') {
      this.UpdateFunctionsPromise().then(() => { }).catch(() => { });
    } else {
      this.Functions$.next([...Object.keys(this.FunctionKeyValue).map(i => this.FunctionKeyValue[i])]);
    }
  }

  UpdateFunctionsPromise() {
    return new Promise((resolve, reject) => {
      this.httpOptions.params = new HttpParams();
      this.httpclientservice.get<any>(`${this.config.baseEndpoint}${this.config.Functions.Endpoint}`, this.httpOptions).subscribe(
        data => {
          this.Functions$.next(data.data);
          for (const item of data.data) {
            this.FunctionKeyValue[item.function_id] = item;
          }
          resolve();
        },
        error => {
          this.waffletoastrservice.showDanger(`Code: ${error.code} - Message: ${error.msg}`, `Community.ToastMsg.QueryFunctionsFail`);
          reject();
        },
      );
    });
  }
}
