import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { WaffleAttendanceService } from '../../../@core/data/waffle-attendance.service';

@Component({
  selector: 'wf-login-shift',
  templateUrl: './login-shift.component.html',
  styleUrls: ['./login-shift.component.scss'],
})
export class LoginShiftComponent implements OnInit {

  user: {
    account: string;
    pwd: string;
  } = {
    account: '',
    pwd: '',
  };

  constructor(private ref: NbDialogRef<LoginShiftComponent>,
    private wfattendanceservice: WaffleAttendanceService) { }

  ngOnInit() {
  }

  onLogin() {
    if (this.user.account !== '' && this.user.pwd) {
      this.wfattendanceservice.postShiftsLogin(this.user.account, this.user.pwd)
      .then(() => {
        this.dismiss(true);
      })
      .catch(() => {});
    }
  }

  dismiss(e: boolean = false) {
    this.ref.close(e);
  }

}
