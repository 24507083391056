import { environment } from '../environments/environment';
export interface ApiConfig {
  baseEndpoint: string;
  login?: {
    Endpoint: string;
  };
  user?: {
    Endpoint: string;
    profile?: {
      Endpoint: string;
    };
    info?: {
      Endpoint: string;
    };
    group?: {
      Endpoint: string;
      remove?: {
        Endpoint: string;
      };
    };
  };
  community?: {
    Endpoint: string;
    info?: {
      Endpoint: string;
    };
    manager?: {
      Endpoint: string;
    };
  };
  communities?: {
    Endpoint: string;
    registrationCounts: {
      Endpoint: string;
    };
    committees: {
      Endpoint: string;
      organizations: {
        Endpoint: string;
      };
    };
    families: {
      Endpoint: string;
    };
    managers: {
      Endpoint: string;
    };
  };
  family?: {
    Endpoint: string;
    query?: {
      Endpoint: string;
    };
    info?: {
      Endpoint: string;
    };
  };
  families?: {
    Endpoint: string;
    code: string;
    users: string;
  };
  leases?: {
    Endpoint: string;
  };
  Patrol?: {
    Endpoint: string;
    CheckPoints: {
      Endpoint: string;
    };
    CheckPoint: {
      Endpoint: string;
    };
    Routes: {
      Endpoint: string;
    };
    Route: {
      Endpoint: string;
    };
    Clocking: {
      Endpoint: string;
    };
    Clockings: {
      Endpoint: string;
    };
  };
  Facility?: {
    Endpoint: string;
    Facilities: {
      Endpoint: string;
    };
    Facility: {
      Endpoint: string;
      Thumbnail: {
        Endpoint: string;
      };
      Images: {
        Endpoint: string;
      };
    };
  };
  AuthorizedStore?: {
    Endpoint: string;
    AuthorizedStores: {
      Endpoint: string;
    };
    AuthorizedStore: {
      Endpoint: string;
    };
  };
  AuthorizedCard?: {
    Endpoint: string;
    AuthorizedCards: {
      Endpoint: string;
    };
    AuthorizedCard: {
      Endpoint: string;
    };
  };
  Card?: {
    Endpoint: string;
    Cards: {
      Endpoint: string;
    };
    Card: {
      Endpoint: string;
    };
  };
  Board?: {
    Endpoint: string;
    Boards: {
      Endpoint: string;
      types: {
        Endpoint: string;
      };
    };
    Board: {
      Endpoint: string;
    };
  };
  Package?: {
    Endpoint: string;
    Packages: {
      Endpoint: string;
      Search: {
        Endpoint: string;
      };
      Counts: {
        Endpoint: string;
      };
    };
    Package: {
      Endpoint: string;
    };
  };
  Functions?: {
    Endpoint: string;
  };
  Repair?: {
    Endpoint: string;
    Items: {
      Endpoint: string;
    };
    Actions: {
      Endpoint: string;
    };
    Configs: {
      Endpoint: string;
      Groups: {
        Endpoint: string;
      };
    };
    Facilities: {
      Endpoint: string;
      Progresses: {
        Endpoint: string;
      };
      Reports: {
        Endpoint: string;
        Managers: {
          Endpoint: string;
        };
      };
      Counts: {
        Endpoint: string;
      };
    };
    Facility: {
      Endpoint: string;
      Count: {
        Endpoint: string;
      };
    };
  };
  Assets?: {
    Endpoint: string;
    Reservation: {
      Endpoint: string;
    };
    Counts: {
      Endpoint: string;
    };
  };
  Chat?: {
    Endpoint: string;
    Topics: {
      Endpoint: string;
      Closed: {
        Endpoint: string;
      }
      Messages: {
        Endpoint: string;
      }
    };
    Counts: {
      Endpoint: string;
    };
    All: {
      Endpoint: string;
    };
  };
  Visitor?: {
    Endpoint: string;
    Communities: {
      Endpoint: string;
      Configs: {
        Endpoint: string;
      };
      Visitors: {
        Endpoint: string;
        StatusIn: {
          Endpoint: string;
        };
        StatusOut: {
          Endpoint: string;
        };
        StatusCancel: {
          Endpoint: string;
        };
        StatusDelete: {
          Endpoint: string;
        };
        Counts: {
          Endpoint: string;
        };
      };
    };
    Families: {
      Endpoint: string;
      Visitors: {
        Endpoint: string;
      };
    }
  };
  Accounting?: {
    Endpoint: string;
    Expenses: {
      Endpoint: string;
    },
    Fees: {
      Endpoint: string;
    }
    Products: {
      Endpoint: string;
    };
    Orders: {
      Endpoint: string;
    };
    Configs: {
      Endpoint: string;
      Communities: {
        Endpoint: string;
      }
    }
  };
  Booking?: {
    Endpoint: string;
    Products: {
      Endpoint: string;
      Bookings: {
        Endpoint: string;
      }
      Thumbnails: {
        Endpoint: string;
      }
      Images: {
        Endpoint: string;
      }
      Orders: {
        Endpoint: string;
        Payments: {
          Endpoint: string;
        },
      }
    };
    Orders: {
      Endpoint: string;
      Search: {
        Endpoint: string;
      }
    };
    Communities: {
      Endpoint: string;
      Accounts: {
        Endpoint: string;
        GainPoints: {
          Endpoint: string;
        };
      };
    };
    Families: {
      Endpoint: string;
      Accounts: {
        Endpoint: string;
      };
    };
    Accounts: {
      Endpoint: string;
      Points: {
        Endpoint: string;
        Usage: {
          Endpoint: string;
        }
      };
    };
    Points: {
      Endpoint: string;
    };
  };
  Survey: {
    Endpoint: string;
    Topics: {
      Endpoint: string;
      Answer: {
        Endpoint: string;
      };
      Comments: {
        Endpoint: string;
      };
      Notices: {
        Endpoint: string;
      };
    };
  };
}

export const apiconfig: ApiConfig = {
  baseEndpoint: environment.ApiEndPoint,
  login: {
    Endpoint: '/crm/v1/user/login',
  },
  user: {
    Endpoint: '',
    profile: {
      Endpoint: '/crm/v2/users/profile',
    },
    info: {
      Endpoint: '/crm/v2/users',
    },
    group: {
      Endpoint: '',
      remove: {
        Endpoint: '/crm/v1/user/group/remove',
      },
    },
  },
  community: {
    Endpoint: '',
    info: {
      Endpoint: '/crm/v1/community/info',
    },
    manager: {
      Endpoint: '/crm/v1/community/manager',
    },
  },
  communities: {
    Endpoint: '/crm/v2/communities',
    registrationCounts: {
      Endpoint: '/registrationCounts',
    },
    committees: {
      Endpoint: '/committees',
      organizations: {
        Endpoint: '/organizations',
      },
    },
    families: {
      Endpoint: '/families',
    },
    managers: {
      Endpoint: '/managers',
    },
  },
  family: {
    Endpoint: '',
    query: {
      Endpoint: '/crm/v1/family/query',
    },
    info: {
      Endpoint: '/crm/v1/family',
    },
  },
  families: {
    Endpoint: '/crm/v2/families',
    code: '/codes',
    users: '/users',
  },
  leases: {
    Endpoint: '/crm/v1/leases',
  },
  Patrol: {
    Endpoint: '',
    CheckPoints: {
      Endpoint: '/patrol/v1/checkpoints',
    },
    CheckPoint: {
      Endpoint: '/patrol/v1/checkpoint',
    },
    Routes: {
      Endpoint: '/patrol/v1/routes',
    },
    Route: {
      Endpoint: '/patrol/v1/route',
    },
    Clocking: {
      Endpoint: '/patrol/v1/clocking',
    },
    Clockings: {
      Endpoint: '/patrol/v1/clockings',
    },
  },
  Facility: {
    Endpoint: '',
    Facilities: {
      Endpoint: '/assets/v1/facilities',
    },
    Facility: {
      Endpoint: '/assets/v1/facility',
      Thumbnail: {
        Endpoint: '/thumbnail',
      },
      Images: {
        Endpoint: '/images',
      },
    },
  },
  AuthorizedStore: {
    Endpoint: '',
    AuthorizedStores: {
      Endpoint: '/assets/v1/authorizedStores',
    },
    AuthorizedStore: {
      Endpoint: '/assets/v1/authorizedStore',
    },
  },
  AuthorizedCard: {
    Endpoint: '',
    AuthorizedCards: {
      Endpoint: '/assets/v1/authorizedCards',
    },
    AuthorizedCard: {
      Endpoint: '/assets/v1/authorizedCard',
    },
  },
  Card: {
    Endpoint: '',
    Cards: {
      Endpoint: '/assets/v1/cards',
    },
    Card: {
      Endpoint: '/assets/v1/card',
    },
  },
  Board: {
    Endpoint: '',
    Boards: {
      Endpoint: '/biz/v2/boards',
      types: {
        Endpoint: '/types',
      },
    },
    Board: {
      Endpoint: '/biz/v2/board',
    },
  },
  Package: {
    Endpoint: '',
    Packages: {
      Endpoint: '/biz/v2/packages',
      Search: {
        Endpoint: '/search',
      },
      Counts: {
        Endpoint: '/counts',
      },
    },
    Package: {
      Endpoint: '/biz/v2/package',
    },
  },
  Functions: {
    Endpoint: '/crm/v2/functions',
  },
  Repair: {
    Endpoint: '/repair/v2',
    Items: {
      Endpoint: '/items',
    },
    Actions: {
      Endpoint: '/actions',
    },
    Configs: {
      Endpoint: '/configs',
      Groups: {
        Endpoint: '/groups',
      },
    },
    Facilities: {
      Endpoint: '/facilities',
      Progresses: {
        Endpoint: '/progresses',
      },
      Reports: {
        Endpoint: '/reports',
        Managers: {
          Endpoint: '/managers',
        },
      },
      Counts: {
        Endpoint: '/counts',
      },
    },
    Facility: {
      Endpoint: '/facility',
      Count: {
        Endpoint: '/counts',
      },
    },
  },
  Assets: {
    Endpoint: '/assets/v1',
    Reservation: {
      Endpoint: '/reservation',
    },
    Counts: {
      Endpoint: '/counts',
    },
  },
  Chat: {
    Endpoint: '/chat/v1',
    Topics: {
      Endpoint: '/topics',
      Closed: {
        Endpoint: '/closed',
      },
      Messages: {
        Endpoint: '/messages',
      },
    },
    Counts: {
      Endpoint: '/counts',
    },
    All: {
      Endpoint: '/all',
    },
  },
  Visitor: {
    Endpoint: '/visitor/v2',
    Communities: {
      Endpoint: '/communities',
      Configs: {
        Endpoint: '/configs',
      },
      Visitors: {
        Endpoint: '/visitors',
        StatusIn: {
          Endpoint: '/statusIn',
        },
        StatusOut: {
          Endpoint: '/statusOut',
        },
        StatusCancel: {
          Endpoint: '/statusCancel',
        },
        StatusDelete: {
          Endpoint: '/statusDelete',
        },
        Counts: {
          Endpoint: '/counts',
        },
      },
    },
    Families: {
      Endpoint: '/families',
      Visitors: {
        Endpoint: '/visitors',
      },
    },
  },
  Accounting: {
    Endpoint: '/accounting/v1',
    Expenses: {
      Endpoint: '/expenses',
    },
    Fees: {
      Endpoint: '/fees',
    },
    Products: {
      Endpoint: '/products',
    },
    Orders: {
      Endpoint: '/orders',
    },
    Configs: {
      Endpoint: '/configs',
      Communities: {
        Endpoint: '/communities',
      },
    },
  },
  Booking: {
    Endpoint: '/booking/v1',
    Products: {
      Endpoint: '/products',
      Bookings: {
        Endpoint: '/bookings',
      },
      Thumbnails: {
        Endpoint: '/thumbnails',
      },
      Images: {
        Endpoint: '/images',
      },
      Orders: {
        Endpoint: '/orders',
        Payments: {
          Endpoint: '/payments',
        },
      },
    },
    Orders: {
      Endpoint: '/orders',
      Search: {
        Endpoint: '/search',
      },
    },
    Communities: {
      Endpoint: '/communities',
      Accounts: {
        Endpoint: '/accounts',
        GainPoints: {
          Endpoint: '/gainPoints',
        },
      },
    },
    Families: {
      Endpoint: '/families',
      Accounts: {
        Endpoint: '/accounts',
      },
    },
    Accounts: {
      Endpoint: '/accounts',
      Points: {
        Endpoint: '/points',
        Usage: {
          Endpoint: '/usage',
        },
      },
    },
    Points: {
      Endpoint: '/points',
    },
  },
  Survey: {
    Endpoint: '/survey/v1',
    Topics: {
      Endpoint: '/topics',
      Answer: {
        Endpoint: '/answers',
      },
      Comments: {
        Endpoint: '/comments',
      },
      Notices: {
        Endpoint: '/notices',
      },
    },
  },
};
