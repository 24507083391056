import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbAlertModule,
  NbCardModule,
  NbInputModule,
  NbListModule,
  NbSpinnerModule,
  NbCheckboxModule,
  NbProgressBarModule,
  NbPopoverModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';

import {
  FooterComponent,
  HeaderComponent,
  LayoutDirectionSwitcherComponent,
  SearchInputComponent,
  SwitcherComponent,
} from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
} from './pipes';
import {
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { DARK_THEME } from './styles/theme.dark';
import { WAFFLE_THEME } from './styles/theme.waffletheme';
import { WaffleSoftwareTermsComponent } from './components/waffle-software-terms/waffle-software-terms.component';
import { TranslateModule } from '@ngx-translate/core';
import { QrcodeComponent } from './components/qrcode/qrcode.component';
import { ImagePdfUploaderComponent } from './components/image-pdf-uploader/image-pdf-uploader.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DragScrollModule } from 'ngx-drag-scroll';
import { CameraComponent } from './components/camera/camera.component';
import {
  NgbDropdownModule,
  NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';
import { QrcodeScannerComponent } from './components/qrcode-scanner/qrcode-scanner.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { WaffleActionButtonComponent } from './components/waffle-action-button/waffle-action-button.component';
import { CardTableSortDirective } from './directives/card-table-sort.directive';
import { WaffleCardTableComponent } from './components/waffle-card-table/waffle-card-table.component';
import { WaffleListSelectorComponent } from './components/waffle-list-selector/waffle-list-selector.component';
import { WaffleNoDataMsgComponent } from './components/waffle-no-data-msg/waffle-no-data-msg.component';
import { WaffleTelescopicButtonComponent } from './components/waffle-telescopic-button/waffle-telescopic-button.component';
import { WaffleTimeFilterComponent } from './components/waffle-time-filter/waffle-time-filter.component';
import { WeekTimeListComponent } from './components/week-time-list/week-time-list.component';
import { WaffleLoadingDialogComponent } from './components/waffle-loading-dialog/waffle-loading-dialog.component';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { WaffleInfoPopoverComponent } from './components/info-popover/info-popover.component';
import { WaffleScrollTopComponent } from './components/waffle-scroll-top/waffle-scroll-top.component';

import { MomentFormatPipe, MomentFromNowPipe } from './pipes/moment-format.pipe';
import { AlertDialogComponent } from '../@theme/components/alert-dialog/alert-dialog.component';

const BASE_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  TranslateModule,
  ImageCropperModule,
  PdfViewerModule,
  DragScrollModule,
  NgbDropdownModule,
  NgbPaginationModule,
  ZXingScannerModule,
];

const NB_MODULES = [
  NbAlertModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbButtonModule,
  NbInputModule,
  NbListModule,
  NbSpinnerModule,
  NbCheckboxModule,
  NbProgressBarModule,
  NbPopoverModule,
];

const COMPONENTS = [
  SwitcherComponent,
  LayoutDirectionSwitcherComponent,
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  WaffleSoftwareTermsComponent,
  QrcodeComponent,
  ImagePdfUploaderComponent,
  CameraComponent,
  QrcodeScannerComponent,
  WaffleActionButtonComponent,
  WaffleCardTableComponent,
  WaffleListSelectorComponent,
  WaffleNoDataMsgComponent,
  WaffleTelescopicButtonComponent,
  WaffleTimeFilterComponent,
  WeekTimeListComponent,
  WaffleLoadingDialogComponent,
  ImageCropperComponent,
  WaffleInfoPopoverComponent,
  WaffleScrollTopComponent,
  AlertDialogComponent,
];

const ENTRY_COMPONENTS = [
  WaffleSoftwareTermsComponent,
  WeekTimeListComponent,
  WaffleTimeFilterComponent,
  WaffleLoadingDialogComponent,
  AlertDialogComponent,
];

const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  MomentFormatPipe,
  MomentFromNowPipe,
];

const Directive = [
  CardTableSortDirective,
];

@NgModule({
  imports: [...BASE_MODULES, ...NB_MODULES],
  exports: [...BASE_MODULES, ...NB_MODULES, ...PIPES, ...COMPONENTS, ...Directive],
  declarations: [...COMPONENTS, ...PIPES, ...Directive],
  entryComponents: [...ENTRY_COMPONENTS],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'waffletheme',
          },
          [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME, WAFFLE_THEME],
        ).providers,
      ],
    };
  }
}
