<nb-card class="card">
  <nb-card-header *ngIf="src === 'terms'">
    {{ 'SoftwareTerms.ServiceTerms' | translate }}
  </nb-card-header>
  <nb-card-header *ngIf="src === 'privacy'">
    {{ 'SoftwareTerms.Privacy' | translate }}
  </nb-card-header>
  <nb-card-body class="card-body p-0">
    <iframe *ngIf="src === 'terms'"
      class="iframe"
      src="https://wafflecity-policy.web.app/waffle/app/terms.html"></iframe>
    <iframe *ngIf="src === 'privacy'"
      class="iframe"
      src="https://wafflecity-policy.web.app/waffle/app/privacy.html"></iframe>
  </nb-card-body>
  <nb-card-footer class="text-center">
    <button nbButton
      (click)="dismiss()">{{ 'Close' | translate }}</button>
  </nb-card-footer>
</nb-card>
