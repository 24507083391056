import { Injectable } from '@angular/core';

export enum CacheType {
    AttendanceTokenMap = 'Attendance_Token_Map',
    // 注意！因Service登出時並不會消失, 請記得測試登出時service內使用的變數是否有需要被清空
    // 可以參考UserInfo的清空方式
    RepairProgressMap = 'REPAIR_PROGRESS_MAP',
    RepairFacilities = 'REPAIR_FACILITIES',
    RepairFacilityMap = 'REPAIR_FACILITY_MAP',
    PackageInfoMap = 'PACKAGE_INFO_MAP',
    CommunityInfoMap = 'COMMUNITY_INFO_MAP',
    UserInfoMap = 'User_Info_Map',
    FamilyInfoMap = 'Family_Info_Map',
    BookingAccountAddPointWorkMap = 'Booking_Account_AddPoint_Work_Map',
    FamilyMemberSelectorSearchRecordMap = 'Family_Member_Selector_Search_Record_Map',
}


export interface PreUseData {
    community_id?: string;
    lang?: string;
    role?: string;
    font_size?: number;
}

export interface FunctionData {
    functions_id: string;
    name: string;
}

@Injectable()
export class LocalStorageService {
    private PreUseData: PreUseData;

    constructor() { }

    public setData(type: CacheType, value: any) {
        localStorage.setItem(`WAFFLE_MGR_SETTING_${type}`, JSON.stringify(value));
        return;
    }

    public getData(type: CacheType) {
        const value = localStorage.getItem(`WAFFLE_MGR_SETTING_${type}`);
        const data = (value != null) ? JSON.parse(value) : {};
        return data;
    }

    getPreUseData() {
        this.PreUseData = JSON.parse(localStorage.getItem('WafflePreUseData'));
        if (!this.PreUseData) {
            this.PreUseData = {
                community_id: 'none',
                lang: 'none',
                role: 'none',
                font_size: 14,
            };
        }
        return this.PreUseData;
    }

    UpdatePreUseData_Community_id(community_id: string) {
        const _tempPreUseData: PreUseData = {
            community_id: community_id,
            lang: this.PreUseData.lang,
            role: this.PreUseData.role,
            font_size: this.PreUseData.font_size,
        };
        localStorage.setItem('WafflePreUseData', JSON.stringify(_tempPreUseData));
    }

    UpdatePreUseData_Lang(lang: string) {
        const _tempPreUseData: PreUseData = {
            community_id: this.PreUseData.community_id,
            lang: lang,
            role: this.PreUseData.role,
            font_size: this.PreUseData.font_size,
        };
        localStorage.setItem('WafflePreUseData', JSON.stringify(_tempPreUseData));
    }

    UpdatePreUseData_Role(Role: string) {
        const _tempPreUseData: PreUseData = {
            community_id: this.PreUseData.community_id,
            lang: this.PreUseData.lang,
            role: Role,
            font_size: this.PreUseData.font_size,
        };
        localStorage.setItem('WafflePreUseData', JSON.stringify(_tempPreUseData));
    }

    UpdatePreUseData_FontSize(font_size: number) {
        const _tempPreUseData: PreUseData = {
            community_id: this.PreUseData.community_id,
            lang: this.PreUseData.lang,
            role: this.PreUseData.role,
            font_size: font_size,
        };
        localStorage.setItem('WafflePreUseData', JSON.stringify(_tempPreUseData));
    }

    UpdatePreUseData(preusedate: PreUseData) {
        const _tempPreUseData: PreUseData = {
            community_id: (preusedate.community_id && preusedate.community_id !== '') ? preusedate.community_id : this.PreUseData.community_id,
            lang: (preusedate.lang && preusedate.lang !== '') ? preusedate.lang : this.PreUseData.lang,
            role: (preusedate.role && preusedate.role !== '') ? preusedate.role : this.PreUseData.role,
            font_size: (typeof preusedate.font_size === 'number' && preusedate.font_size > 0) ? preusedate.font_size : this.PreUseData.font_size,
        };
        localStorage.setItem('WafflePreUseData', JSON.stringify(_tempPreUseData));
    }
}
