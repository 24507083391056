import { Injectable } from '@angular/core';
import * as moment from 'moment';

type DateType = 'create_at' | 'update_at' | 'primaryUpdateAt';

export interface ITranslate {
  zhTw: string;
  zhCn: string;
  koKr: string;
  en: string;
  jaJp?: string;
}

export interface Image {
  version: string;
  saas: string;
  cloud_path: string;
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {

  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  EARTH_RADIUS = 6378137.0; // 單位M
  PI = Math.PI;

  constructor() { }

  deepCopyArr(arr = []) {
    if (arr === null) return [];

    const copy = [];
    for (const elem of arr) {
      if (Array.isArray(elem)) {
        copy.push(this.deepCopyArr(elem));
      } else {
        if (typeof elem === 'object') {
          copy.push(this.deepCopyObj(elem));
        } else {
          copy.push(elem);
        }
      }
    }
    return copy;
  }

  deepCopyObj(obj = {}) {
    if (obj === null) return {};

    const tempObj = {};
    for (const [key, value] of Object.entries(obj)) {
      if (Array.isArray(value)) {
        tempObj[key] = this.deepCopyArr(value);
      } else {
        if (typeof value === 'object') {
          tempObj[key] = this.deepCopyObj(value);
        } else {
          tempObj[key] = value;
        }
      }
    }
    return tempObj;
  }

  TimeIndexToTimeText(index: number, position: 'begin' | 'end') {
    let _p = 0;
    if (position === 'end') _p += 30;
    return moment().startOf('days').add((index * 30) + _p, 'minutes').format('HH:mm');
  }

  async FilteredContentPromise(value: string, searchParameters: string[], list: any[]) {
    const pageContent = [];
    value = value.trim().toLowerCase();

    for (const list_item of list) {
      let _allParametersStr = '';
      for (const key of searchParameters) {
        _allParametersStr += list_item[key];
      }
      if (_allParametersStr.toLowerCase().indexOf(value) !== -1) {
        pageContent.push(list_item);
      }
    }

    return Promise.resolve(pageContent);
  }

  GetEChartColorArray() {
    return ['rgba(255, 99, 132, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      'rgba(255, 206, 86, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(153, 102, 255, 0.8)',
      'rgba(255, 159, 64, 0.8)'];
  }


  sortNewerToOlder(list: any[], dateType: DateType = 'update_at') {
    if (Array.isArray(list)) {
      return list.sort((L, R) => {
        const L_dateType = dateType === 'primaryUpdateAt'
          ? (L.update_at ? 'update_at' : 'create_at')
          : dateType;
        const R_dateType = dateType === 'primaryUpdateAt'
          ? (R.update_at ? 'update_at' : 'create_at')
          : dateType;
        return moment(R[R_dateType]).diff(moment(L[L_dateType]));
      });
    } else {
      console.error('sortNewerToOlder -> list | The argument is not an array');
    }
  }

  public rotateImage(src: string) {
    return new Promise((res, rej) => {
      if (src) {
        const img = new Image();
        img.crossOrigin = 'anonymous',
          img.src = src as string;
        const canvas = document.createElement('canvas');
        img.onload = () => {
          const width = img.width;
          const height = img.height;

          let ctx = canvas.getContext('2d');

          canvas.width = height;
          canvas.height = width;
          ctx = canvas.getContext('2d');
          ctx.setTransform(
            0, 1,
            -1, 0,
            height, 0,
          );

          ctx.drawImage(img, 0, 0, width, height);

          res(canvas.toDataURL('image/png'));
        };
      } else {
        rej('Image source is not valid');
      }
    });
  }

  resizeImage(src: string, MAX_WIDTH?: number, MAX_HEIGHT?: number): Promise<string> {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      !MAX_WIDTH && (MAX_WIDTH = 1024);
      !MAX_HEIGHT && (MAX_HEIGHT = 1024);
      const canvas = document.createElement('canvas');
      img.onload = () => {
        let width = img.width;
        let height = img.height;

        const ctx = canvas.getContext('2d');

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);
        res(canvas.toDataURL('image/jpeg', 0.8));
      };

    });
  }

  getBrowserVersion() {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M;
  }

  getOperatingSystemVersion() {
    let OSName;
    if (navigator.appVersion.indexOf('Win') !== -1) OSName = 'Windows';
    if (navigator.appVersion.indexOf('Mac') !== -1) OSName = 'MacOS';
    if (navigator.appVersion.indexOf('X11') !== -1) OSName = 'UNIX';
    if (navigator.appVersion.indexOf('Linux') !== -1) OSName = 'Linux';
    return OSName;
  }

  async getLocation() {
    let _str = '';
    if (navigator.geolocation) {
      await new Promise<string>((r, j) => {
        navigator.geolocation.getCurrentPosition(
          position => r(`${position.coords.latitude},${position.coords.longitude}`),
          () => j(``),
        );
      }).then(e => _str = e).catch(() => { });
    }
    return _str;
  }

  getFilterTimeLine(id: 'today' | 'yesterday' | 'threedaysago' | 'oneweekago' | 'morethanthreedays' | 'morethansevendays') {
    let _begin, _end;
    switch (id) {
      case 'today':
        _begin = moment().startOf('days').unix();
        _end = moment().unix();
        break;
      case 'yesterday':
        _begin = moment().subtract(1, 'days').startOf('days').unix();
        _end = moment().subtract(1, 'days').endOf('days').unix();
        break;
      case 'threedaysago':
        _begin = moment().subtract(3, 'days').startOf('days').unix();
        _end = moment().unix();
        break;
      case 'oneweekago':
        _begin = moment().subtract(7, 'days').startOf('days').unix();
        _end = moment().unix();
        break;
      case 'morethanthreedays':
        _begin = moment().subtract(3, 'days').startOf('days').subtract(1, 'years').unix();
        _end = moment().subtract(3, 'days').startOf('days').unix();
        break;
      case 'morethansevendays':
        _begin = moment().subtract(7, 'days').startOf('days').subtract(1, 'years').unix();
        _end = moment().subtract(7, 'days').startOf('days').unix();
        break;
    }
    return {
      begin: _begin,
      end: _end,
    };
  }

  /**
  * 計算距離單位米
  * @param {Object} lat1
  * @param {Object} lng1
  * @param {Object} lat2
  * @param {Object} lng2
  */
  getFlatternDistance(lat1: number, lng1: number, lat2: number, lng2: number) {
    let radLat1 = this.getRad(lat1);
    let radLat2 = this.getRad(lat2);

    let radLon1 = this.getRad(lng1);
    let radLon2 = this.getRad(lng2);

    if (radLat1 < 0)
      radLat1 = Math.PI / 2 + Math.abs(radLat1); // south
    if (radLat1 > 0)
      radLat1 = Math.PI / 2 - Math.abs(radLat1); // north
    if (radLon1 < 0)
      radLon1 = Math.PI * 2 - Math.abs(radLon1); // west
    if (radLat2 < 0)
      radLat2 = Math.PI / 2 + Math.abs(radLat2); // south
    if (radLat2 > 0)
      radLat2 = Math.PI / 2 - Math.abs(radLat2); // north
    if (radLon2 < 0)
      radLon2 = Math.PI * 2 - Math.abs(radLon2); // west
    const x1 = this.EARTH_RADIUS * Math.cos(radLon1) * Math.sin(radLat1);
    const y1 = this.EARTH_RADIUS * Math.sin(radLon1) * Math.sin(radLat1);
    const z1 = this.EARTH_RADIUS * Math.cos(radLat1);

    const x2 = this.EARTH_RADIUS * Math.cos(radLon2) * Math.sin(radLat2);
    const y2 = this.EARTH_RADIUS * Math.sin(radLon2) * Math.sin(radLat2);
    const z2 = this.EARTH_RADIUS * Math.cos(radLat2);

    const d = Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2) + (z1 - z2) * (z1 - z2));
    // 余弦定理求夹角
    const theta = Math.acos((this.EARTH_RADIUS * this.EARTH_RADIUS + this.EARTH_RADIUS * this.EARTH_RADIUS - d * d) / (2 * this.EARTH_RADIUS * this.EARTH_RADIUS));
    const dist = theta * this.EARTH_RADIUS;
    return dist;
  }

  /**
  * 轉換弧度
  * @param d
  * @returns {number}
  */
  private getRad(d: number): number {
    const PI = Math.PI;
    return d * PI / 180.0;
  }
}

